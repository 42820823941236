import Button from '@/components/Button/Button';
import { ButtonSizes, ButtonVariants } from '@/components/Button/Button.types';
import HeaderAdmin from '@/components/HeaderAdmin/HeaderAdmin';
import InputLabel from '@/components/InputsFormik/InputLabel/InputLabel';
import InputSelect from '@/components/InputsFormik/InputSelect/InputSelect';
import InputText from '@/components/InputsFormik/InputText/InputText';
import { useAppDispatch } from '@/index';
import { RoleTypes } from '@/models/Role';
import { postUserThunk } from '@/store/users/postUser.slice';
import { Form, Formik, FormikProps } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './CreateUser.module.scss';
import { CreateUserSchema } from './CreateUser.yup';
interface UserForm {
    cif: string;
    username: string;
    email: string;
    maintenance_type?: { value: string; label: string };
    password?: string;
}

const CreateUser = () => {
    const params = useParams();
    const appDispatch = useAppDispatch();
    const navigate = useNavigate();
    const initialValues = {
        username: '',
        email: '',
        cif: '',
        maintenance_type: { value: 'premium', label: 'Premium' }
    };

    const handleSubmit = (values: UserForm, actions: any) => {
        appDispatch(
            postUserThunk({
                cif: values.cif,
                email: values.email,
                //maintenance_type: values.maintenance_type,
                maintenance_type: 'premium',
                username: values.username,
                role: params.role ? params.role : 'client'
            })
        ).then(() => {
            navigate('/user-management');
        });
    };

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <HeaderAdmin
                    titleBold="Administración"
                    //@ts-ignore
                    title={`/ GESTIÓN DE USUARIOS/ NUEVO ${RoleTypes[params.role]}`}
                />
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={CreateUserSchema}
                    onSubmit={handleSubmit}
                >
                    {(formik: FormikProps<UserForm>) => (
                        <>
                            <Form className={styles.formContainer}>
                                <div className={styles.formContent}>
                                    <InputText
                                        label={'Nombre completo'}
                                        name="username"
                                        placeholder="Apellido Apellido ,Nombre "
                                    />
                                    <InputText
                                        label={'Correo'}
                                        name="email"
                                        placeholder="Dirección de correo electronico"
                                    />
                                    <InputText
                                        label={'Documentación CIF/NIF'}
                                        name="cif"
                                        placeholder="Número de documento"
                                    />
                                    <InputLabel
                                        label={'Contraseña'}
                                        name="password"
                                        placeholder="Se enviará al cliente su contraseña a la cuenta de correo proporcionada"
                                    />

                                    {params.role === 'client' && (
                                        <InputSelect
                                            label={'Tipo de mantenimiento'}
                                            name="maintenance_type"
                                            placeholder="NIF/CIF proporcionado..."
                                            disabled={true}
                                            options={[
                                                { value: 'none', label: 'Ninguno' },
                                                { value: 'standard', label: 'Standard' },
                                                { value: 'premium', label: 'Premium' }
                                            ]}
                                        />
                                    )}
                                </div>
                                <div className={styles.message}>
                                    Recuerda que el número de documentación sera tu nombre de
                                    usuario
                                </div>

                                <div className={styles.button}>
                                    <Button
                                        type={'submit'}
                                        size={ButtonSizes.ADAPTABLE}
                                        variant={ButtonVariants.PRIMARY}
                                    >
                                        CONTINUAR
                                    </Button>
                                </div>
                            </Form>
                        </>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default CreateUser;
