import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { RootState } from '@/store/rootReducer';
import accessToken from '@/utils/helpers/accessToken.helper';

function AdminLayoutController() {
    const navigate = useNavigate();

    const isAuthenticated = useSelector((state: RootState) => state.auth.login.isAuthenticated);

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login', {
                replace: true
            });
        }
    });
    const onCloseSession = () => {
        accessToken.remove();
        navigate('/login', {
            replace: true
        });
        location.reload();
    };

    return { onCloseSession };
}

export default AdminLayoutController;
