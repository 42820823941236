import styles from './SearchBar.module.scss';
import Select from 'react-select';
import { QueryOption } from './SearchBar.types';
import customStylesSelect from './SearchBar.styles';
import SearchIcon from '@/assets/icons/search.svg';
interface SearchBarProps {
    searchText: string;
    setSearchText: (v: string) => void;
    searchByValue: QueryOption;
    setSearchByValue: (v: QueryOption) => void;
    onButtonClick: () => void;
}
const SearchBar = ({
    searchText,
    searchByValue,
    setSearchText,
    setSearchByValue,
    onButtonClick
}: SearchBarProps) => {
    const options = [
        { value: 'client', label: 'Clientes' },
        { value: 'name', label: 'Nombre de planta' }
    ];
    const onSearchBySelectChange = (value: any) => setSearchByValue(value);
    const handleChangeSearchText = (value: any) => setSearchText(value.target.value);
    return (
        <div className={styles.container}>
            <input
                className={styles.input}
                onChange={handleChangeSearchText}
                type="text"
                placeholder="Buscar cliente..."
            />
            <Select
                value={searchByValue}
                options={options}
                onChange={onSearchBySelectChange}
                styles={customStylesSelect}
                className={styles.select}
            />
            <div className={styles.containerIcon} onClick={onButtonClick}>
                <SearchIcon className={styles.icon} />
            </div>
        </div>
    );
};

export default SearchBar;
