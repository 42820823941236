.tableCell {
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon {
    height: 30px;
    width: 30px;
    object-fit: contain;
    color: orange;
    &__active {
        color: green;
    }
    &__inactive {
        color: red;
    }
    &__unreachable {
        color: orange;
    }
}
