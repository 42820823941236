import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './layout/Layout';
import PublicRoute from './layout/Routes/PublicRoute';

import AdminRoutes from './layout/Routes/RoutesByUserType/AdminRoutes';
import ClientRoutes from './layout/Routes/RoutesByUserType/ClientRoutes';
import Login from './pages/Login/Login';
import userRole from './utils/helpers/userRole.helper';
import { UserRoleTypes } from './layout/Layout';
import PrivateRoute from './layout/Routes/PrivateRoute';
import { NotFound } from './pages/NotFound/NotFound';
import { RootState } from './store/rootReducer';
import { useSelector } from 'react-redux';
import RecoverPassword from './pages/RecoverPassword/RecoverPassword';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const App = () => {
    // TODO change PublicRoute to PrivateRoute when needed
    const userType = userRole.get();
    const isAuthenticated = useSelector((rs: RootState) => rs.auth.login.isAuthenticated);

    const routesHandler = {
        [UserRoleTypes.ADMIN]: AdminRoutes.map((props, i) => <Route {...props} key={i} />),
        [UserRoleTypes.TECHNICIAN]: AdminRoutes.map((props, i) => <Route {...props} key={i} />),
        [UserRoleTypes.CLIENT]: ClientRoutes.map((props, i) => <Route {...props} key={i} />)
    };

    return (
        <BrowserRouter>
            <Layout>
                <Routes>
                    {/*@ts-ignore */}
                    {isAuthenticated && userType && routesHandler[userType]}
                    <Route
                        path="/login"
                        element={
                            <PublicRoute>
                                <Login />
                            </PublicRoute>
                        }
                    />
                    <Route
                        path="/recover-password"
                        element={
                            <PublicRoute>
                                <RecoverPassword />
                            </PublicRoute>
                        }
                    />
                    <Route
                        path="*"
                        element={
                            <PrivateRoute>
                                <NotFound />
                            </PrivateRoute>
                        }
                    />
                </Routes>
            </Layout>
            <ToastContainer
                position="top-right"
                limit={3}
                autoClose={4000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </BrowserRouter>
    );
};

export default App;
