.mainContainer {
    margin: 0px 80px;
}

.title {
    font-size: 22px;
    color: #777;
    margin: 0px;
    font-weight: bold;
    text-transform: uppercase;
}

.table {
    align-self: center;
    margin: 30px 0px;
}

.tableHeaderRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

}

.headerCell {
    flex: 1;
    margin-right: 1px;
    background-color: #fe6b03;
    padding: 10px 0px;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
}

.tableBodyRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    font-size: 14px;
    border: 1px solid #fe6b03;
    border-top: 0px;
}

.bodyCell {
    flex: 1;
    padding: 10px 0px;
    text-align: center;
    align-self: flex-end;
}

.spinnerContainer {
    display: flex;
    justify-content: center;
    border: 1px solid #fe6b03;
}


.moreInfoContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 50px;
}

.moreInfoTable {
    flex: 1;
    margin-right: 40px;
    margin-top: 20px;
}

.moreInfoTableBodyRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    border: 1px solid #fe6b03;
    padding: 0px 30px;

}