.formContainer {
    background-color: #eee;
    min-width: 800px;
    display: grid;
    grid-template-columns: 150px 150px 150px 300px 100px;
    grid-template-rows: auto;
    gap: 30px;
    align-items: start;
    justify-items: center;
    padding: 20px;
    border-radius: 10px;
    .element {
        display: flex;
        justify-content: flex-start;
        align-self: flex-start;
        align-items: center;
        width: 100%;
    }
}

.buttonContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .button {
        width: 50px;
        height: 50px;
        align-self: center;
        .buttonText {
            font-weight: bolder;
            font-size: 30px;
            position: relative;
            top: -5px;
        }
    }
}
.rates {
    width: auto;
    &__title {
        font-weight: bolder;
    }
    &__grid {
        display: grid;
        grid-template-columns: 100px 100px 100px;
        gap: 10px;
    }
}
