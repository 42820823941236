.button {
    color: var(--white);
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 1;
    font-size: var(--font-large);
    font-weight: bold;
    padding: 0.5em 0 0.5em 0;
    border-radius: 5px;
    &__primary {
        border: 2px solid var(--color-primary);
        background-color: var(--color-primary);
        color: white;

        &:hover,
        &:focus {
            opacity: 0.9;
            border: 2px solid var(--color-primary);
            background-color: var(--color-primary);
            color: white;
        }
    }
    &__primaryOutLine {
        border: 2px solid var(--color-primary);
        background-color: white;
        color: var(--color-primary);

        &:hover,
        &:focus {
            opacity: 0.9;
            border: 2px solid var(--color-primary);
            background-color: var(--color-primary);
            color: white;
        }
    }

    &__success {
        border: 2px solid #00c3a5;
        background-color: #00c3a5;
        color: white;
        &:hover,
        &:focus {
            opacity: 0.7;
        }
    }
    &__successOutLine {
        border: 2px solid #00c3a5;
        background-color: #00c3a5;
        color: white;
        &:hover,
        &:focus {
            opacity: 0.7;
        }
    }
    &__warning {
        border: 2px solid orange;
        background-color: orange;
        color: white;
        &:hover,
        &:focus {
            opacity: 0.7;
        }
    }
    &__danger {
        border: 2px solid tomato;
        background-color: tomato;
        color: white;

        &:hover,
        &:focus {
            border: 2px solid rgb(249, 122, 100);
            background-color: rgb(249, 122, 100);
            color: white;
        }
    }
    &__dangerOutLine {
        border: 2px solid tomato;
        background-color: white;
        color: tomato;

        &:hover,
        &:focus {
            border: 2px solid tomato;
            background-color: tomato;
            color: white;
        }
    }
    &__disabled {
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.3;
    }

    &__xsmall {
        font-size: 1rem;
        padding: 0 0.5em;
    }
    &__small {
        font-size: 1.4rem;
        padding: 0.5em;
    }
    &__medium {
        font-size: 2rem;
        padding: 0.5em 0 0.5em 0;
    }
    &__large {
        font-size: var(--font-large);
        font-weight: bold;
        padding: 0.5em;
    }
    &__adaptable {
        width: 100%;
        height: 100%;
        font-size: 1em;
    }
}
