interface GuestUserLayoutProps {
    children?: React.ReactNode;
}

import { useNavigate } from 'react-router-dom';
import LOGO_BIYECTIVA from '../../assets/images/LOGO_BIYECTIVA.png';
import Logo_EMSOLAR from '../../assets/images/Logo_EMSOLAR.png';

import styles from './GuestUserLayout.module.scss';

const GuestUserLayout = ({ children }: GuestUserLayoutProps) => {
    const navigate = useNavigate();
    return (
        <>
            <div className={styles.header}>
                <img
                    className={styles.logo}
                    src={Logo_EMSOLAR}
                    onClick={() => {
                        navigate('/login');
                    }}
                />
                <img className={styles.logo} src={LOGO_BIYECTIVA} />
            </div>
            <div className={styles.container}>{children}</div>
        </>
    );
};

export default GuestUserLayout;
