.stationDetailsItem {
    display: flex;
    flex-direction: column;
    flex: 1;
    border: 2px solid #fe6b03;
    margin: 5px;
    padding: 5px;
    align-items: center;
    justify-content: flex-end;
    padding: 30px;
    height: 550px;
}

.stationDetailsItemTitleRow {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: baseline;
    width: -webkit-fill-available;
}

.stationDetailsItemTitle {
    font-size: 22px;
    color: #777777;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    text-align: center;
}

.image {
    width: 50%;
    object-fit: contain;
    width: '100%';
    height: 385px;
}

.emsolarLogoContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
}

.emsolarLogo {
    height: 85px;
    width: 90px;
    padding-right: 120px;
}

.emsolarLogo:hover {
    cursor: pointer;
}
