import styles from './TimeRangeSelector.module.scss'
import UpIcon from '../../assets/icons/UpIcon.png'
import DownIcon from '../../assets/icons/DownIcon.png'
import { useState } from 'react';
import { TimeRangeOptions } from '@/models/TimeRangeOptions';


const OPTIONS: TimeRangeSelectorOptionsProps[] = [{
    label: 'Esta semana',
    value: 'week'
}, {
    label: 'Este mes',
    value: 'month'
},
{
    label: 'Este año',
    value: 'year'
}
];

interface TimeRangeSelectorProps {
    value: string;
    onSelected: (value: TimeRangeOptions) => void;
}

interface TimeRangeSelectorOptionsProps {
    label: string;
    value: TimeRangeOptions
}

const TimeRangeSelector = ({ value, onSelected }: TimeRangeSelectorProps) => {

    const [isOpen, setOpen] = useState<boolean>(false)

    const selectedValue: TimeRangeSelectorOptionsProps = OPTIONS.find((option) => value === option.value) ?? OPTIONS[1];


    const onSelectedItem = (value: TimeRangeOptions) => {
        onSelected(value)
        setOpen(false)
    }



    return (
        <div>
            <div className={styles.selectedValueContainer} onClick={() => setOpen(open => !open)}>
                {selectedValue.label}
                <img className={styles.icon} src={isOpen ? UpIcon : DownIcon} />
            </div>
            {isOpen && (
                <div className={styles.optionsContainer}>
                    {OPTIONS.map(item => (
                        <div className={styles.optionItem} onClick={() => onSelectedItem(item.value)}>
                            {item.label}
                        </div>
                    ))}
                </div>

            )}
        </div>
    )
}

export default TimeRangeSelector