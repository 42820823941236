.tableCell {
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon {
    height: 40px;
    width: 40px;
    object-fit: contain;
}
.container {
    width: 100%;
    height: var(--body-height);
    background-color: rgba(128, 128, 128, 0.144);

    .content {
        padding: 30px;
        display: flex;
        flex-direction: column;
        gap: 40px;

        .tableContainer {
            height: 100%;
            width: 100%;
            .tableOptions {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                .addButton {
                    width: 50px;
                    height: 50px;
                    font-size: 20px;
                    border: 2.5px solid var(--color-primary);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 7px;
                    font-size: 30px;
                    color: var(--color-primary);
                    cursor: pointer;
                }
                .addButton:hover {
                    color: white;
                    background-color: var(--color-primary);
                }
                .searchBar {
                    display: flex;
                    flex-direction: row;
                    justify-content: right;
                    align-items: center;
                    width: 700px;
                    &__input {
                        border: 1px solid var(--color-primary);
                        outline: none;
                        color: var(--color-primary);
                        width: 250px;
                        height: 45px;
                        border-radius: 5px 0 0 5px;
                        padding: 0 10px 0 10px;
                        font-size: 20px;
                    }
                    &__input::placeholder {
                        color: var(--color-primary);
                    }
                    &__button {
                        width: 50px;
                        height: 50px;
                        background-color: var(--color-primary);
                        color: white;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        border-radius: 0 5px 5px 0;
                        &--icon {
                            margin: 15px;
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }
            .table {
                margin-top: 20px;
                overflow-x: scroll;
            }
        }
    }
}

.modalContent {
    min-width: 300px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 40px;
}
