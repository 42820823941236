import { useEffect, useState } from 'react';
import styles from './TimeSelector.module.scss';
import TimeField from 'react-simple-timefield';
interface TimeSelectorProps {
    startTime?: string;
    onChangeStartTime: (value: string) => void;
    endTime?: string;
    onChangeEndTime: (value: string) => void;
}

const TimeSelector = ({
    startTime,
    onChangeStartTime,
    endTime,
    onChangeEndTime
}: TimeSelectorProps) => {
    const [is24Hours, setIs24Hours] = useState(true);

    useEffect(() => {
        if (startTime && endTime) {
            setIs24Hours(false);
        }
    }, [startTime, endTime]);

    return (
        <div className={styles.timeSelectorContainer}>
            <div className={styles.is24HoursContainer} onClick={() => setIs24Hours(!is24Hours)}>
                <div className={styles.label}>24 h</div>
                <div className={is24Hours ? styles.activeDot : styles.inactiveDot} />
            </div>
            {!is24Hours && (
                <>
                    <div className={styles.label}>Hora de inicio:</div>

                    <TimeField
                        value={startTime ?? undefined}
                        onChange={(value) => {
                            onChangeStartTime(value.target.value);
                        }}
                        style={{
                            width: '40px',
                            padding: '5px 15px',
                            borderRadius: '5px',
                            border: '1px solid #ccc',
                            backgroundColor: '#fcfcfc'
                        }}
                    />

                    <div className={styles.label}>Hora de fin:</div>
                    <TimeField
                        style={{
                            width: '40px',
                            padding: '5px 15px',
                            borderRadius: '5px',
                            border: '1px solid #ccc',
                            backgroundColor: '#fcfcfc'
                        }}
                        onChange={(value) => {
                            const p = value.target.value;
                            onChangeEndTime(p);
                        }}
                        value={endTime ?? undefined}
                    />
                </>
            )}
        </div>
    );
};

export default TimeSelector;
