import { ColumnDef } from '@tanstack/react-table';
import Table from '@/components/HomeTable/HomeTable';
import { Link } from 'react-router-dom';
import HomeController from './Home.controller';
import styles from './Home.module.scss';
import CheckBox from '@/components/Checkbox/Checkbox';
import { StationList } from '@/models/StationList';
import PlantIcon from '@/assets/icons/plant.svg';
import TowerIcon from '@/assets/icons/tower.svg';
import TimeIcon from '@/components/TimeIcon/TimeIcon';
import Pagination from '@/components/Pagination/Pagination';

const Home = () => {
    const {
        setSelectedStationAction,
        stations,
        selectedStationId,
        handleChangePage,
        max_pages,
        current_page
    } = HomeController();

    const onSelectedRow = (newSelectedStationId?: string) => {
        if (newSelectedStationId === selectedStationId) {
            setSelectedStationAction(undefined);
        } else {
            setSelectedStationAction(newSelectedStationId);
        }
    };

    const columns: ColumnDef<StationList>[] = [
        {
            id: 'selector',
            header: 'Planta',
            cell: (info) => (
                <div
                    onClick={() => onSelectedRow(info.row.original.id)}
                    className={styles.tableCell}
                >
                    <CheckBox checked={info.row.original.id === selectedStationId} />
                </div>
            )
        },
        {
            header: 'Estado',
            cell: (info) => (
                <div className={styles.tableCell}>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                        <div className={styles.icon}>
                            <PlantIcon className={styles[`icon__${info.row.original.status}`]} />
                        </div>
                        <div className={styles.icon}>
                            <TowerIcon className={styles[`icon__${info.row.original.status}`]} />
                        </div>
                    </div>
                </div>
            )
        },

        {
            accessorKey: 'name',
            cell: (info) => (
                <Link
                    to={info.row.original.id}
                    onClick={(e) => onSelectedRow(info.row.original.id)}
                >
                    {info.getValue()}
                </Link>
            ),
            header: 'Nombre'
        },
        {
            accessorKey: 'total_capacity',
            cell: (info: any) => info.getValue()?.toLocaleString('de-DE') + ' kWp',
            header: 'Capacidad Total'
        },
        {
            accessorKey: 'power',
            cell: (info: any) => info.getValue()?.toLocaleString('de-DE') + ' kW',
            header: 'Potencia Actual'
        },
        {
            accessorKey: 'today_accumulated_energy',
            cell: (info: any) => info.getValue()?.toLocaleString('de-DE') + ' kWh',
            header: 'Rendimiento Hoy'
        },
        {
            accessorKey: 'total_accumulated_energy',
            // https://stackoverflow.com/questions/57628055/tolocalestring-not-working-on-numbers-less-than-10000-in-all-browsers
            cell: (info: any) => Number(info.getValue())?.toLocaleString('de-DE') + ' kWp',
            header: 'Rendimiento Total'
        },
        {
            header: 'Previsión climatológica',
            cell: (info) => (
                <div>
                    <div className={styles.tableCell}>
                        <TimeIcon
                            status={info.row.original.weather.status ?? 'unknown'}
                            //status="clear"
                            className={styles.icon}
                        />
                        {info.row.original.weather.temperature}ºC
                    </div>
                </div>
            )
        }
    ];

    return (
        <>
            <Table selectedStationId={selectedStationId} data={stations ?? []} columns={columns} />
            <Pagination
                handleChangePage={handleChangePage}
                maxPages={max_pages}
                currentPage={current_page}
            />
        </>
    );
};

export default Home;
