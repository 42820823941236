import { StylesConfig } from 'react-select';

const customStylesSelect: StylesConfig = {
    control: (base, state) => ({
        ...base,
        border: state.isFocused
            ? '1px solid var(--color-primary-low)'
            : `1px solid var(--color-primary-low);`,
        background: 'var(--color-primary-low)',
        color: 'var(--color-primary) !important',
        boxShadow: 'none',
        height: '100%',
        borderRadius: 'none',
        '&:hover': {
            borderColor: 'none'
        }
    }),
    option: (provided, state) => ({
        ...provided,
        background: state.isFocused ? 'var(--color-primary-low)' : 'white',
        color: state.isFocused ? 'var(--color-primary)' : 'var(--color-primary)',
        borderColor: 'var(--color-primary);'
    }),
    menu: (provided) => ({
        ...provided,
        color: 'var(--color-primary)',
        zIndex: 'var(--z-level-3)',
        borderColor: 'var(--color-primary)'
    }),
    input: (styles) => ({ ...styles, color: 'var(--color-primary)' }),
    placeholder: (styles) => ({ ...styles, color: 'var(--color-primary)' }),
    singleValue: (styles) => ({ ...styles, color: 'var(--color-primary)' })
};
export default customStylesSelect;
