import DaySelector from '@/components/DaySelector/DaySelector';
import PlantNavigationHeader from '@/components/StationNavigationHeader/StationNavigationHeader';
import TimeSelector from '@/components/TimeSelector/TimeSelector';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CreateEditAlarmController from './CreateEditAlarm.controller';
import styles from './CreateEditAlarm.module.scss';

const CreateEditAlarm = () => {
    const { alarm, stationStrings, setAlarm, onSaveEditAlarm, setSelectedStationAction, error } =
        CreateEditAlarmController();
    const { stationId, alarmId } = useParams();

    useEffect(() => {
        if (stationId) {
            setSelectedStationAction(stationId);
        }
    }, [stationId]);

    const goBack = () => {
        window.history.back();
    };

    return (
        <div>
            <PlantNavigationHeader
                showBack
                subsectionText={
                    alarmId === undefined ? 'ALARMAS / NUEVA ALARMA' : 'ALARMAS / EDITAR'
                }
                onBack={goBack}
            />
            <div className={styles.mainContainer}>
                <div className={styles.labelInputContainer}>
                    <label className={styles.label}>String</label>
                    <select
                        className={styles.selectInput}
                        value={alarm.string_id}
                        onChange={(e) => setAlarm({ ...alarm, string_id: e.target.value })}
                    >
                        <option key={undefined} value={undefined}>
                            Selecciona un String
                        </option>
                        {stationStrings?.map((string) => (
                            <option key={string.id} value={string.id}>
                                {string.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className={styles.labelInputContainer}>
                    <label className={styles.label}>Valor mínimo (Voltios)</label>
                    <input
                        className={styles.input}
                        type="number"
                        value={alarm?.min_value ? alarm?.min_value : ''}
                        onChange={(value) => {
                            setAlarm({
                                ...alarm,
                                min_value: parseFloat(value.target.value) ?? undefined
                            });
                        }}
                    />
                </div>
                <div className={styles.labelInputContainer}>
                    <label className={styles.label}>Valor máximo (Voltios)</label>
                    <input
                        className={styles.input}
                        type="number"
                        value={alarm?.max_value}
                        onChange={(value) => {
                            setAlarm({
                                ...alarm,
                                max_value: parseFloat(value.target.value) ?? undefined
                            });
                        }}
                    />
                </div>
                <div className={styles.labelInputContainer}>
                    <label className={styles.label}>Días a notificar</label>
                    <DaySelector
                        value={alarm?.days}
                        onChange={(value) => setAlarm({ ...alarm, days: value })}
                    />
                </div>
                <div className={styles.labelInputContainer}>
                    <label className={styles.label}>Hora</label>
                    <TimeSelector
                        startTime={alarm.start_time}
                        onChangeStartTime={(value) =>
                            setAlarm({ ...alarm, start_time: value + ':00' })
                        }
                        endTime={alarm.end_time}
                        onChangeEndTime={(value) => setAlarm({ ...alarm, end_time: value + ':00' })}
                    />
                </div>
                <div className={styles.labelInputContainer}>
                    {error !== '' && <div className={styles.error}>{error}</div>}
                </div>
                <button className={styles.button} onClick={() => onSaveEditAlarm(alarmId)}>
                    Guardar
                </button>
            </div>
        </div>
    );
};

export default CreateEditAlarm;
