import Button from '@/components/Button/Button';
import { ButtonSizes, ButtonVariants } from '@/components/Button/Button.types';
import styles from './UserManagement.module.scss';
import SearchIcon from '@/assets/icons/search.svg';
import { useAppDispatch } from '@/index';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/rootReducer';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { MyModal } from '@/components/MyModal/MyModal';
import HeaderAdmin from '@/components/HeaderAdmin/HeaderAdmin';
import { getUsersThunk } from '@/store/users/getUsers.slice';
import UsersTable from '@/components/UsersTable/UsersTable';
import userRole from '@/utils/helpers/userRole.helper';
import { UserRoleTypes } from '@/layout/Layout';
import { generateFilter } from '@/utils/helpers/filter.helper';
import Pagination from '@/components/Pagination/Pagination';

const UserManagement = () => {
    const dispatch = useAppDispatch();
    const [openModal, setOpenModal] = useState<boolean>(false);

    const userType = userRole.get();
    const [searchText, setSearchText] = useState<string>('');

    const { usersList, max_pages, current_page } = useSelector(
        (state: RootState) => state.users.getUsers
    );

    const navigate = useNavigate();
    const getUsers = () => {
        dispatch(
            getUsersThunk(generateFilter({ username: searchText, limit: 8, page: current_page }))
        );
    };

    const handleChangePage = (page: number) => {
        dispatch(getUsersThunk(generateFilter({ username: searchText, limit: 8, page: page })));
    };
    useEffect(() => {
        getUsers();
    }, []);

    return (
        <>
            <div className={styles.container}>
                <div className={styles.content}>
                    <HeaderAdmin titleBold="Administración /&nbsp;" title="GESTIÓN DE USUARIOS" />

                    <div className={styles.tableContainer}>
                        <div className={styles.tableOptions}>
                            <div
                                className={styles.addButton}
                                onClick={() => {
                                    setOpenModal(true);
                                }}
                            >
                                +
                            </div>
                            <div className={styles.searchBar}>
                                <input
                                    className={styles.searchBar__input}
                                    onChange={(e) => {
                                        setSearchText(e.target.value);
                                    }}
                                    type="text"
                                />
                                <div
                                    className={styles.searchBar__button}
                                    onClick={() => {
                                        getUsers();
                                    }}
                                >
                                    <SearchIcon className={styles['searchBar__button--icon']} />
                                </div>
                            </div>
                        </div>
                        <div className={styles.table}>
                            <UsersTable
                                headers={['USUARIO', 'E-MAIL', 'TIPO DE USUARIO', 'ADMINISTRAR']
                                    .concat(userType === UserRoleTypes.ADMIN ? ['INSPECCIONAR'] : [])}
                                rows={usersList ? usersList : []}
                            ></UsersTable>
                        </div>
                        <Pagination
                            handleChangePage={handleChangePage}
                            maxPages={max_pages}
                            currentPage={current_page}
                        />
                    </div>
                </div>
            </div>

            <MyModal
                isOpen={openModal}
                title="Añadir nuevo usuario"
                handleClose={() => {
                    setOpenModal(false);
                }}
            >
                <div className={styles.modalContent}>
                    {userType === UserRoleTypes.ADMIN && (
                        <>
                            <Button
                                size={ButtonSizes.ADAPTABLE}
                                variant={ButtonVariants.PRIMARY}
                                onClick={() => {
                                    navigate('/create-user/admin');
                                }}
                            >
                                Administrador
                            </Button>
                            <Button
                                size={ButtonSizes.ADAPTABLE}
                                variant={ButtonVariants.PRIMARY}
                                onClick={() => {
                                    navigate('/create-user/technician');
                                }}
                            >
                                Técnico
                            </Button>
                        </>
                    )}

                    <Button
                        size={ButtonSizes.ADAPTABLE}
                        variant={ButtonVariants.PRIMARY}
                        onClick={() => {
                            navigate('/create-user/client');
                        }}
                    >
                        Cliente
                    </Button>
                </div>
            </MyModal>
        </>
    );
};

export default UserManagement;
