import accessToken from '@/utils/helpers/accessToken.helper';
import request, { ApiRequest, ApiResponse } from '@/utils/helpers/apiResponse.helper';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { UserStation } from './getUserStations.slice';

export interface patchNoUserStationState {
    isLoading: boolean;
    error: Error | null;
    userStation: UserStation | null;
}

export const patchNoUserStationThunk = createAsyncThunk<
    UserStation,
    { idStation: string; data: any },
    { rejectValue: Error }
>('userStations/patchNoUserStation', async (params, thunkApi) => {
    return request({
        url: `/api/users/stations/${params.idStation}`,
        method: 'PATCH',
        extraHeaders: {
            Authorization: `Bearer ${accessToken.get()}`
        },
        data: params.data
    } as ApiRequest)
        .then((res: ApiResponse<UserStation>) => {
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            return thunkApi.rejectWithValue(err.data);
        });
});

export const patchNoUserStationSlice = createSlice({
    name: 'noUserStation',
    initialState: { isLoading: false, error: null, userStation: null } as patchNoUserStationState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(patchNoUserStationThunk.fulfilled, (state, { payload }) => {
            (state.isLoading = false), (state.error = null), (state.userStation = payload);
        });
    }
});
