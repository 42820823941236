import classNames from 'classnames';
import CheckBox from '../Checkbox/Checkbox';
import styles from './UserStationsTable.module.scss';
import EditIcon from '@/assets/icons/pencil-square.svg';
import DeleteIcon from '@/assets/icons/trash.svg';

interface StationsTableProps {
    headers: string[];
    rows: any[];
    options?: { edit: boolean; delete: boolean };
    onEditClick: (value: string) => void;
    onDeleteClick: (value: string) => void;
    onCheck: (value: string) => void;
    selectedItems?: any;
    isCheckable?: boolean;
}

const UserStationsTable = ({
    headers,
    rows,
    onEditClick,
    onCheck,
    selectedItems,
    isCheckable,
    options,
    onDeleteClick
}: StationsTableProps) => {
    return (
        <table className={styles.table}>
            <thead>
                <tr>
                    {headers.map((header, index) => {
                        return (
                            <th key={index} className={styles.tableHeader}>
                                {header}
                            </th>
                        );
                    })}
                </tr>
            </thead>
            <tbody>
                {rows.map((row, key) => {
                    return (
                        <tr
                            style={{
                                backgroundColor: 'white'
                            }}
                            key={key}
                        >
                            {isCheckable && (
                                <td className={styles.tableCell}>
                                    <CheckBox
                                        checked={selectedItems[row.id] ? true : false}
                                        onClick={() => {
                                            onCheck(row.id);
                                        }}
                                    />
                                </td>
                            )}

                            <td className={styles.tableCell}>{row.name}</td>
                            <td className={styles.tableCell}>{row.pricing_type}</td>
                            <td className={styles.tableCell}>{row.tariff}</td>

                            {/* <td className={classNames(styles.tableCell, styles.psContainer)}>
                                {row.tiers &&
                                    Object.keys(row.tiers).map((e, i) => {
                                        if (row.tiers[e] !== '' && row.tiers[e] !== null) {
                                            return (
                                                <div key={'e' + i} className={styles.psItem}>
                                                    <div className={styles.psItem__section}>
                                                        {e}:&nbsp;{row.tiers[e]}&nbsp;€
                                                    </div>
                                                </div>
                                            );
                                        } else {
                                            return <div>-</div>;
                                        }
                                    })}
                            </td> */}

                            {options && (
                                <td className={styles.tableCell}>
                                    {options.edit && (
                                        <EditIcon
                                            className={styles.icon}
                                            onClick={() => {
                                                onEditClick(row.id);
                                            }}
                                        />
                                    )}
                                    {options.delete && (
                                        <DeleteIcon
                                            className={styles.icon}
                                            onClick={() => {
                                                onDeleteClick(row.id);
                                            }}
                                        />
                                    )}
                                </td>
                            )}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default UserStationsTable;
