.layout {
    width: 100%;
    min-height: 100vh;
    height: 100%;
}

.header {
    min-height: var(--nav-height);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 50px;
    padding-left: 50px;
}

.logo {
    height: 50px;
    cursor: pointer;
}
