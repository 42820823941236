import styles from './EditStation.module.scss';
import HeaderAdmin from '@/components/HeaderAdmin/HeaderAdmin';
import { Form, Formik, FormikProps } from 'formik';
import InputText from '@/components/InputsFormik/InputText/InputText';
import Button from '@/components/Button/Button';
import { ButtonSizes, ButtonVariants } from '@/components/Button/Button.types';
import InputDate from '@/components/InputsFormik/InputDate/InputDate';
import { MyModal } from '@/components/MyModal/MyModal';
import InputSelect from '@/components/InputsFormik/InputSelect/InputSelect';
import InputTextV2 from '@/components/InputsFormik/InputTextV2/InputTextV2';
import EditStationController, { TariffDataFormik } from './EditStation.controller';
import AddRow from './Sections/AddRow/AddRow';
import TrashIcon from '@/assets/icons/trash.svg';

const EditStation = () => {
    const {
        initialValues,
        handleOnSubmit,
        setOpenModal,
        openModal,
        handleSaveTariff,
        generateStructure,
        station
    } = EditStationController();
    return (
        <>
            <div className={styles.container}>
                <div className={styles.content}>
                    <HeaderAdmin
                        titleBold="Administración"
                        //@ts-ignore
                        title={`/ GESTIÓN DE INSTALACIONES/${station?.name}`}
                    />
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        onSubmit={handleOnSubmit}
                    >
                        {(
                            formik: FormikProps<{
                                name: string;
                                //tariff: string;
                                address: string;
                                cost: number;
                                start_time: string;
                                latitude: number;
                                longitude: number;
                            }>
                        ) => (
                            <>
                                <Form className={styles.formContainer}>
                                    <div className={styles.formContent}>
                                        <div className={styles.formContent__firstGrid}>
                                            <InputText
                                                label={'Nombre de la instalación'}
                                                name="name"
                                                placeholder="..."
                                                handleDisabled={true}
                                            />
                                            <InputText
                                                label={'Coste (€)'}
                                                name="cost"
                                                placeholder="..."
                                                handleDisabled={true}
                                                type={'number'}
                                            />

                                            <InputDate name="start_time" label="Fecha de inicio:" />

                                            <InputText
                                                label={'Dirección'}
                                                name="address"
                                                placeholder="..."
                                                handleDisabled={true}
                                            />
                                            <InputText
                                                label={'Latitud'}
                                                name="latitude"
                                                placeholder="..."
                                                handleDisabled={true}
                                            />
                                            <InputText
                                                label={'Longitud'}
                                                name="longitude"
                                                placeholder="..."
                                                handleDisabled={true}
                                            />

                                            <div
                                                style={{
                                                    width: '200px',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: '10px'
                                                }}
                                            >
                                                <label htmlFor="" style={{ fontWeight: 'bold' }}>
                                                    Tarifa
                                                </label>
                                                <Button
                                                    size={ButtonSizes.ADAPTABLE}
                                                    variant={ButtonVariants.PRIMARY_OUTLINE}
                                                    onClick={() => setOpenModal(true)}
                                                >
                                                    Editar datos de tarifa
                                                </Button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.button}>
                                        <Button
                                            type={'submit'}
                                            size={ButtonSizes.ADAPTABLE}
                                            variant={ButtonVariants.PRIMARY}
                                        >
                                            Guardar Cambios
                                        </Button>
                                    </div>
                                </Form>
                            </>
                        )}
                    </Formik>
                </div>
            </div>
            <MyModal
                handleClose={() => {
                    setOpenModal(false);
                }}
                title={`EDITAR INSTALACIÓN `}
                isOpen={openModal}
            >
                <div className={styles.containerModal}>
                    <Formik
                        enableReinitialize
                        initialValues={generateStructure()}
                        // validationSchema={StationByUserSchema}
                        onSubmit={handleSaveTariff}
                    >
                        {(formik: FormikProps<{ data: TariffDataFormik[] }>) => (
                            <>
                                <Form className={styles.formContainer}>
                                    <div className={styles.formContent}>
                                        <div className={styles.tableHeader}>
                                            <div className={styles.header}>Fecha de inicio</div>
                                            <div className={styles.header}>
                                                Modalidad de tarifa contratada por el cliente
                                            </div>
                                            <div className={styles.header}>
                                                Tarifa contratada por el cliente
                                            </div>

                                            <div className={styles.header}>
                                                Introducir valores de tarifas en € por (kw/h)
                                            </div>
                                            <div className={styles.header}></div>
                                            <div className={styles.br} />
                                            <div className={styles.br} />
                                            <div className={styles.br} />
                                            <div className={styles.br} />
                                            <div className={styles.br} />
                                        </div>
                                        <div className={styles.tableContent}>
                                            {formik.values.data.map((e, i) => {
                                                return (
                                                    <>
                                                        <div className={styles.element}>
                                                            <InputDate name={`data[${i}].date`} />
                                                        </div>
                                                        <div className={styles.element}>
                                                            <InputSelect
                                                                name={`data[${i}].pricing_type`}
                                                                options={[
                                                                    {
                                                                        value: 'fixed',
                                                                        label: 'Importes fijo '
                                                                    },
                                                                    {
                                                                        value: 'pool',
                                                                        label: 'Cliente a pool'
                                                                    }
                                                                ]}
                                                            />
                                                        </div>
                                                        <div className={styles.element}>
                                                            {e.pricing_type.value !== 'pool' && (
                                                                <InputSelect
                                                                    name={`data[${i}].tariff`}
                                                                    options={[
                                                                        {
                                                                            value: '2.0TD',
                                                                            label: '2.0TD'
                                                                        },
                                                                        {
                                                                            value: '3.0TD',
                                                                            label: '3.0TD'
                                                                        },
                                                                        {
                                                                            value: '6.1TD',
                                                                            label: '6.1TD'
                                                                        }
                                                                    ]}
                                                                />
                                                            )}
                                                        </div>

                                                        <div className={styles.rates}>
                                                            {e.pricing_type.value !== 'pool' && (
                                                                <div className={styles.rates__grid}>
                                                                    <InputTextV2
                                                                        label={'P1:'}
                                                                        label2={'€'}
                                                                        name={`data[${i}]tiers.P1`}
                                                                        placeholder=""
                                                                        type="number"
                                                                    />
                                                                    <InputTextV2
                                                                        label={'P2:'}
                                                                        label2={'€'}
                                                                        name={`data[${i}]tiers.P2`}
                                                                        placeholder=""
                                                                        type="number"
                                                                    />
                                                                    <InputTextV2
                                                                        label={'P3:'}
                                                                        label2={'€'}
                                                                        name={`data[${i}]tiers.P3`}
                                                                        placeholder=""
                                                                        type="number"
                                                                    />
                                                                    {(e.tariff.value === '3.0TD' ||
                                                                        e.tariff.value ===
                                                                            '6.1TD') && (
                                                                        <>
                                                                            <InputTextV2
                                                                                label={'P4:'}
                                                                                label2={'€'}
                                                                                name={`data[${i}]tiers.P4`}
                                                                                placeholder=""
                                                                                type="number"
                                                                            />
                                                                            <InputTextV2
                                                                                label={'P5:'}
                                                                                label2={'€'}
                                                                                name={`data[${i}]tiers.P5`}
                                                                                placeholder=""
                                                                                type="number"
                                                                            />
                                                                            <InputTextV2
                                                                                label={'P6:'}
                                                                                label2={'€'}
                                                                                name={`data[${i}]tiers.P6`}
                                                                                placeholder=""
                                                                                type="number"
                                                                            />
                                                                        </>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>

                                                        <div className={styles.deleteRow}>
                                                            <Button
                                                                size={ButtonSizes.ADAPTABLE}
                                                                variant={ButtonVariants.PRIMARY}
                                                                onClick={() => {
                                                                    if (
                                                                        Array.isArray(
                                                                            formik.values.data
                                                                        )
                                                                    ) {
                                                                        const newArr =
                                                                            formik.values.data;
                                                                        newArr.splice(i, 1);
                                                                        newArr.sort(function (
                                                                            a: TariffDataFormik,
                                                                            b: TariffDataFormik
                                                                        ) {
                                                                            const keyA = new Date(
                                                                                a.date
                                                                            );
                                                                            const keyB = new Date(
                                                                                b.date
                                                                            );

                                                                            if (keyA < keyB)
                                                                                return 1;
                                                                            if (keyA > keyB)
                                                                                return -1;
                                                                            return 0;
                                                                        });
                                                                        formik.setValues({
                                                                            data: newArr
                                                                        });
                                                                    }
                                                                }}
                                                            >
                                                                <TrashIcon
                                                                    className={
                                                                        styles.deleteRow__icon
                                                                    }
                                                                />
                                                            </Button>
                                                        </div>
                                                    </>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <div className={styles.addRow}>
                                        <AddRow />
                                    </div>
                                    <div className={styles.button}>
                                        <Button
                                            type={'button'}
                                            size={ButtonSizes.ADAPTABLE}
                                            variant={ButtonVariants.PRIMARY}
                                            onClick={() => handleSaveTariff(formik.values)}
                                        >
                                            Guardar Cambios
                                        </Button>
                                    </div>
                                </Form>
                            </>
                        )}
                    </Formik>
                </div>
            </MyModal>
        </>
    );
};

export default EditStation;
