import { useAppDispatch } from '@/index';
import { TimeRangeOptions } from '@/models/TimeRangeOptions';
import { RootState } from '@/store/rootReducer';
import { getStationDetailByIdThunk } from '@/store/stations/stations.slice';
import { useState } from 'react';
import { useSelector } from 'react-redux';

type WastedEnergyChartChartData = {
    injection: number;
    self_consumption: number;
    network_import: number;
    key: string;
    range: number[];
};

function WastedEnergyChartController() {
    const dispatch = useAppDispatch();

    const data = useSelector((state: RootState) => state.stations.stationDetail?.wasted_energy);
    const selectedStationId = useSelector((state: RootState) => state.stations.selectedStationId);

    const [timeRangeSelectorValue, setTimeRangeSelectorValue] = useState<TimeRangeOptions>('week');

    const onChangeTimeRangeSelectorValue = (value: TimeRangeOptions) => {
        setTimeRangeSelectorValue(value);
        if (selectedStationId) {
            dispatch(
                getStationDetailByIdThunk({
                    station_id: selectedStationId,
                    time_range: value,
                    data_type: 'wasted_energy'
                })
            );
        }
    };

    const parsedData: WastedEnergyChartChartData[] | undefined = data
        ? data.injection.map((item, index) => ({
              injection: item.value,
              key: item.key,
              self_consumption: data.self_consumption[index].value,
              network_import: data.network_import[index].value,
              range:
                  item.value !== undefined && data.self_consumption[index].value !== undefined && data.network_import[index].value !== undefined
                      ? [item.value, data.self_consumption[index].value]
                      : []
          }))
        : undefined;

    return {
        parsedData,
        timeRangeSelectorValue,
        onChangeTimeRangeSelectorValue
    };
}

export default WastedEnergyChartController;
