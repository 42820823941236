import accessToken from '@/utils/helpers/accessToken.helper';
import request, { ApiResponse } from '@/utils/helpers/apiResponse.helper';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

interface UsersCredential {
    provider: string;
    username: string;
    password: string;
}
export interface postUserState {
    isLoading: boolean;
    user: UsersCredential | null;
}

export const postUsersCredentialsThunk = createAsyncThunk<
    UsersCredential,
    {},
    { rejectValue: Error }
>('users/postUsersCredentials', async (params, thunkApi) => {
    return request({
        url: '/api/users-credentials',
        method: 'POST',
        extraHeaders: {
            Authorization: `Bearer ${accessToken.get()}`
        },
        data: params
    })
        .then((res: ApiResponse<UsersCredential>) => {
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            return thunkApi.rejectWithValue(err.data);
        });
});
export const postUserSlice = createSlice({
    name: 'postUser',
    initialState: { isLoading: false, user: null } as postUserState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(postUsersCredentialsThunk.fulfilled, (state, { payload }) => {
            (state.isLoading = false), (state.user = payload);
        });
    }
});
