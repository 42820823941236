.mainContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    padding: 0 50px 0 50px;
    grid-template-rows: repeat(auto-fill, 100px);
}

.documentationElement {
    display: flex;
    flex-direction: column;
    margin: 0px 40px;
    max-width: 150px;
}

.documentationElement:hover {
    cursor: pointer;
}

.icon {
    height: 130px;
    object-fit: contain;
}

.documentationText {
    font-size: 22px;
    color: #777;
    text-align: center;
}

.addIcon {
    height: 70px;
    object-fit: contain;
    align-self: center;
    margin-left: 20px;
}

.addIcon:hover {
    cursor: pointer;
}

.fileTypeContainer {
    background-color: #f5bb78;
    border-radius: 15px;
    padding: 30px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 15px;
    overflow: hidden;
    min-height: 200px;
    max-height: 200px;
    height: 100%;
    &__deleteOption {
        position: relative;
        top: 35px;
        left: calc(100% - 50px);
        border: 1px solid whitesmoke;
        background-color: tomato;
        color: whitesmoke;
        width: 25px;
        height: 25px;
        border-radius: 15px;
        text-align: center;
        font-weight: bolder;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
}

.fileTypeIcon {
    height: 100px;
    object-fit: contain;
    cursor: pointer;
}

.fileTypeText {
    text-transform: uppercase;
    font-size: 25px;
    font-size: 1.2vw;
    font-weight: bold;
    margin-top: 30px;
    max-width: 200px;
    min-height: 80px;
    max-height: 80px;
    overflow: hidden;
}

.uploadContainer {
    border: 2px dashed;
    margin: 60px;
    padding: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.uploadIcon {
    height: 150px;
    object-fit: contain;
}

.uploadButton {
    background-color: #e8721f;
    border-radius: 5px;
    padding: 10px;
    font-size: 18px;
    text-transform: uppercase;
}

.uploadButton:hover {
    cursor: pointer;
}
