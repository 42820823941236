import Button from '@/components/Button/Button';
import { ButtonSizes, ButtonVariants } from '@/components/Button/Button.types';
import HeaderAdmin from '@/components/HeaderAdmin/HeaderAdmin';
import InputSelect from '@/components/InputsFormik/InputSelect/InputSelect';
import InputText from '@/components/InputsFormik/InputText/InputText';
import InputTextV2 from '@/components/InputsFormik/InputTextV2/InputTextV2';
import { useAppDispatch } from '@/index';
import { postStationThunk } from '@/store/stations/postStation.slice';
import { Form, Formik, FormikProps } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './CreateStation.module.scss';
import { CreateStationSchema } from './CreateStation.yup';
const CreateStation = () => {
    const params = useParams();
    const appDispatch = useAppDispatch();
    const navigate = useNavigate();
    const initialValues = {
        provider: { value: 'huawei', label: 'Huawei' },
        accesUser: '',
        password: '',
        contractedRateByClient: { value: 'fijo', label: 'Importe fijo ' },
        station: { value: 'none', label: 'Selecciona una instalación' },
        contractedRate: { value: '2.0TD', label: '2.0TD' }
    };
    const handleSubmit = (values: any, actions: any) => {
        appDispatch(
            postStationThunk({
                // cif: values.documentation,
                // email: values.email,
                // maintenance_type: values.typeOfMaintenance.value,
                // username: values.name,
                // role: params.role
            })
        );
        navigate(`/edit-user/${params.id}`);
    };

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <HeaderAdmin
                    titleBold="Administración"
                    //@ts-ignore
                    title={`/ GESTIÓN DE USUARIOS/${params.id}/ NUEVA INSTALACIÓN`}
                />
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={CreateStationSchema}
                    onSubmit={handleSubmit}
                >
                    {(
                        formik: FormikProps<{
                            provider: { value: string; label: string };
                            accesUser: string;
                            password: string;
                            contractedRateByClient: { value: string; label: string };
                            station: { value: string; label: string };
                            contractedRate: { value: string; label: string };
                        }>
                    ) => (
                        <>
                            <Form className={styles.formContainer}>
                                <div className={styles.formContent}>
                                    <InputSelect
                                        label={'Modalidad de tarifa contratada por el cliente'}
                                        name="provider"
                                        options={[{ value: 'huawei', label: 'Huawei' }]}
                                    />
                                    <InputText
                                        label={'Usuario de acceso'}
                                        name="accesUser"
                                        placeholder="Usuario para acceder al inversor"
                                    />
                                    <InputText
                                        label={'Contraseña'}
                                        name="password"
                                        placeholder="Contraseña del usuario"
                                    />
                                    <InputSelect
                                        label={'Instalación'}
                                        name="station"
                                        options={[
                                            { value: '1', label: 'Instalación 1' },
                                            { value: '2', label: 'Instalación 2' }
                                        ]}
                                    />

                                    <InputSelect
                                        label={'Modalidad de tarifa contratada por el cliente'}
                                        name="contractedRateByClient"
                                        options={[
                                            { value: 'fijo', label: 'Importes fijo ' },
                                            { value: 'pool', label: 'Cliente a pool' }
                                        ]}
                                    />

                                    <InputSelect
                                        label={'Tarifa contratada por el cliente'}
                                        name="contractedRate"
                                        options={[
                                            { value: '2.0TD', label: '2.0TD' },
                                            { value: '3.0TD', label: '3.0TD' },
                                            { value: '6.1TD', label: '6.1TD' }
                                        ]}
                                    />

                                    <div className={styles.rates}>
                                        <div className={styles.rates__title}>
                                            Introducir valores de tarifas en € por (kw/h)
                                        </div>
                                        <div className={styles.rates__grid}>
                                            <InputTextV2
                                                label={'P1:'}
                                                label2={'€'}
                                                name="P1"
                                                placeholder=""
                                                type="number"
                                            />
                                            <InputTextV2
                                                label={'P2:'}
                                                label2={'€'}
                                                name="P2"
                                                placeholder=""
                                                type="number"
                                            />
                                            <InputTextV2
                                                label={'P3:'}
                                                label2={'€'}
                                                name="P3"
                                                placeholder=""
                                                type="number"
                                            />
                                            <InputTextV2
                                                label={'P4:'}
                                                label2={'€'}
                                                name="P4"
                                                placeholder=""
                                                type="number"
                                            />
                                            <InputTextV2
                                                label={'P5:'}
                                                label2={'€'}
                                                name="P5"
                                                placeholder=""
                                                type="number"
                                            />
                                            <InputTextV2
                                                label={'P6:'}
                                                label2={'€'}
                                                name="P6"
                                                placeholder=""
                                                type="number"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.button}>
                                    <Button
                                        type={'submit'}
                                        size={ButtonSizes.ADAPTABLE}
                                        variant={ButtonVariants.PRIMARY}
                                    >
                                        AÑADIR INSTALACIÓN
                                    </Button>
                                </div>
                            </Form>
                        </>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default CreateStation;
