.daySelectorContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.dayUnselectedItem {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 20px;
    text-align: center;
    margin: 2px;
    color: #777;
    font-weight: bold;
    font-size: 20px;
    background-color: #fcfcfc;
}

.dayUnselectedItem:hover {
    cursor: pointer;
}

.daySelectedItem {
    padding: 10px;
    background-color: #f9ad0b;
    border-radius: 5px;
    border: 1px solid #ccc;
    color: white;
    width: 20px;
    text-align: center;
    margin: 2px;
    font-weight: bold;
    font-size: 20px;
}

.daySelectedItem:hover {
    cursor: pointer;
}