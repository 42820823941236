.container {
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 15px;
    border-radius: 15px;
}

.icon {
    height: 30px;
    padding: 10px;

}

.input {
    // width: 20px;
    margin: 10px;
    border: none;
    color: #e66c23;
    font-size:  30px;
}

.input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #e66c23;
    opacity: 1;
    /* Firefox */
}

.input:focus {
    outline: none;
}