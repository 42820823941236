import Button from '@/components/Button/Button';
import { ButtonSizes, ButtonVariants } from '@/components/Button/Button.types';
import InputDate from '@/components/InputsFormik/InputDate/InputDate';
import InputSelect from '@/components/InputsFormik/InputSelect/InputSelect';
import InputTextV2 from '@/components/InputsFormik/InputTextV2/InputTextV2';
import { currentDate } from '@/utils/helpers/date.helper';
import { Form, Formik, FormikProps, useFormikContext } from 'formik';
import { TariffDataFormik } from '../../EditStation.controller';
import styles from './AddRow.module.scss';

const AddRow = ({}: { initialState?: TariffDataFormik }) => {
    const { values, setValues }: any = useFormikContext();
    const initialState: TariffDataFormik = {
        date: currentDate(),
        pricing_type: {
            value: 'fixed',
            label: 'Importes fijo '
        },
        tariff: { value: '2.0TD', label: '2.0TD' },
        tiers: {
            P1: '0',
            P2: '0',
            P3: '0',
            P4: '0',
            P5: '0',
            P6: '0'
        }
    };

    return (
        <Formik
            enableReinitialize
            initialValues={initialState}
            onSubmit={() => {}}
            // validationSchema={StationByUserSchema}
        >
            {(formik: FormikProps<TariffDataFormik>) => (
                <>
                    <Form className={styles.formContainer}>
                        <div className={styles.element}>
                            <InputDate name="date" />
                        </div>
                        <div className={styles.element}>
                            <InputSelect
                                name={`pricing_type`}
                                options={[
                                    {
                                        value: 'fixed',
                                        label: 'Importes fijo '
                                    },
                                    {
                                        value: 'pool',
                                        label: 'Cliente a pool'
                                    }
                                ]}
                            />
                        </div>
                        <div className={styles.element}>
                            {formik.values.pricing_type.value !== 'pool' && (
                                <InputSelect
                                    name={`tariff`}
                                    options={[
                                        {
                                            value: '2.0TD',
                                            label: '2.0TD'
                                        },
                                        {
                                            value: '3.0TD',
                                            label: '3.0TD'
                                        },
                                        {
                                            value: '6.1TD',
                                            label: '6.1TD'
                                        }
                                    ]}
                                />
                            )}
                        </div>
                        <div className={styles.rates}>
                            {formik.values.pricing_type.value !== 'pool' && (
                                <div className={styles.rates__grid}>
                                    <InputTextV2
                                        label={'P1:'}
                                        label2={'€'}
                                        name="tiers.P1"
                                        placeholder=""
                                        type="number"
                                    />
                                    <InputTextV2
                                        label={'P2:'}
                                        label2={'€'}
                                        name="tiers.P2"
                                        placeholder=""
                                        type="number"
                                    />
                                    <InputTextV2
                                        label={'P3:'}
                                        label2={'€'}
                                        name="tiers.P3"
                                        placeholder=""
                                        type="number"
                                    />
                                    {(formik.values.tariff.value === '3.0TD' ||
                                        formik.values.tariff.value === '6.1TD') && (
                                        <>
                                            <InputTextV2
                                                label={'P4:'}
                                                label2={'€'}
                                                name="tiers.P4"
                                                placeholder=""
                                                type="number"
                                            />
                                            <InputTextV2
                                                label={'P5:'}
                                                label2={'€'}
                                                name="tiers.P5"
                                                placeholder=""
                                                type="number"
                                            />
                                            <InputTextV2
                                                label={'P6:'}
                                                label2={'€'}
                                                name="tiers.P6"
                                                placeholder=""
                                                type="number"
                                            />
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className={styles.buttonContainer}>
                            <div className={styles.button}>
                                <Button
                                    size={ButtonSizes.ADAPTABLE}
                                    variant={ButtonVariants.PRIMARY}
                                    onClick={() => {
                                        if (Array.isArray(values.data)) {
                                            const newArr = values.data;
                                            newArr.push(formik.values);
                                            newArr.sort(function (
                                                a: TariffDataFormik,
                                                b: TariffDataFormik
                                            ) {
                                                const keyA = new Date(a.date);
                                                const keyB = new Date(b.date);

                                                if (keyA < keyB) return -1;
                                                if (keyA > keyB) return 1;
                                                return 0;
                                            });
                                            setValues({ data: newArr });
                                            formik.setValues(initialState);
                                        }
                                    }}
                                >
                                    <div className={styles.buttonText}>+</div>
                                </Button>
                            </div>
                        </div>
                    </Form>
                </>
            )}
        </Formik>
    );
};

export default AddRow;
