.container {
    background-color: rgb(245, 243, 243);
    min-height: var(--body-height);

    .content {
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 40px;

        .formContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 50px;
            .formContent {
                width: 800px;
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 20px;
            }
            .button {
                width: 200px;
                height: 50px;
            }
            .rates {
                &__title {
                    font-weight: bolder;
                }
                &__grid {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 20px;
                }
            }
        }
    }
}
