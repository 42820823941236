.container {
    border: 1px solid #fe6b03;
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.titleRow {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: -webkit-fill-available;
    margin-bottom: 50px;
}

.title {
    font-size: 30px;
    color: #777;
    text-transform: uppercase;

}