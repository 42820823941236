import styles from './InputLabel.module.scss';

interface InputTextProps {
    label: string;
    type?: 'text' | 'number' | 'date';
    placeholder?: string;
    [x: string]: any;
}
const InputLabel = ({ label, ...props }: InputTextProps) => {
    return (
        <>
            <div className={styles.container}>
                <label className={styles.label}>{label}</label>
                <div className={styles.input}>{props.placeholder}</div>
            </div>
        </>
    );
};

export default InputLabel;
