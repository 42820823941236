.table {
    width: 100%;
    border-spacing: 0px 0px;
}

.tableHeader {
    background-color: #fe6b03;
    color: white;
    padding: 20px 50px;
    font-size: 20px;
    border-right: 4px solid white;
    text-transform: uppercase;
}

.tableCell {
    border-bottom: 1px solid lightgrey;
    align-self: center;
    margin: 0px;
    text-align: center;
    padding: 10px;
    font-size: 20px;
}
.icon {
    height: 20px;
    cursor: pointer;
}
.icon:hover {
    opacity: 0.7;
}
