import { useAppDispatch } from '@/index';
import { RootState } from '@/store/rootReducer';
import { getStationDeviceByIdThunk, setSelectedStationId } from '@/store/stations/stations.slice';
import { generateFilter } from '@/utils/helpers/filter.helper';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

function StationDeviceController() {
    const dispatch = useAppDispatch();

    const setSelectedStationAction = (selectedStationId?: string) =>
        dispatch(setSelectedStationId(selectedStationId));

    const stationDeviceData = useSelector((state: RootState) => state.stations.stationDeviceData);
    const current_page = useSelector(
        (state: RootState) => state.stations.stationDeviceData?.strings.current_page
    );
    const max_pages = useSelector(
        (state: RootState) => state.stations.stationDeviceData?.strings.max_pages
    );

    const { stationId, deviceId } = useParams();

    const fetchStationDevice = async (id: string, deviceId: string) => {
        setSelectedStationAction(id);
        dispatch(
            getStationDeviceByIdThunk({
                station_id: id,
                device_id: deviceId,
                currrentParams: { limit: 8, page: current_page }
            })
        );
    };

    const handleChangePage = (page: number) => {
        if (stationId && deviceId) {
            dispatch(
                getStationDeviceByIdThunk({
                    station_id: stationId,
                    device_id: deviceId,
                    currrentParams: generateFilter({ limit: 8, page })
                })
            );
        }
    };

    useEffect(() => {
        if (stationId && deviceId) {
            fetchStationDevice(stationId, deviceId);
        }
    }, [stationId, deviceId]);

    return {
        stationDeviceData,
        handleChangePage,
        current_page,
        max_pages
    };
}

export default StationDeviceController;
