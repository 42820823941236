import { useAppDispatch } from "@/index";
import { TimeRangeOptions } from "@/models/TimeRangeOptions";
import { RootState } from "@/store/rootReducer";
import { getStationDetailByIdThunk } from "@/store/stations/stations.slice";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";


function ProducedCapitalChartController() {
    const dispatch = useAppDispatch()

    const data = useSelector((state: RootState) => state.stations.stationDetail?.economic_performance);
    const [timeRangeSelectorValue, setTimeRangeSelectorValue] = useState<TimeRangeOptions>('week')

    const { stationId } = useParams()
     
    useEffect(() => {
        if (stationId && data === undefined) {
            dispatch(getStationDetailByIdThunk({
                station_id: stationId,
            }))
        }
    } , [])

    const onChangeTimeRangeSelectorValue = (value: TimeRangeOptions) => {
        setTimeRangeSelectorValue(value);
        if (stationId) {
            dispatch(getStationDetailByIdThunk({
                station_id: stationId,
                time_range: value,
                data_type: 'economic_performance'
            }))
        }
    }

    return {
        data, timeRangeSelectorValue, onChangeTimeRangeSelectorValue
    };

}

export default ProducedCapitalChartController;
