import accessToken from '@/utils/helpers/accessToken.helper';
import request, { ApiResponse } from '@/utils/helpers/apiResponse.helper';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

interface Station {
    user_id: string;
    name: string;
    address: string;
    cost: number;
    // amortization_table: [];
    start_time: Date;
}
export interface postStationState {
    isLoading: boolean;
    contract: Station | null;
}

export const postStationThunk = createAsyncThunk<Station, {}, { rejectValue: Error }>(
    'contracts/postStation',
    async (params, thunkApi) => {
        return request({
            url: '/api/stations',
            method: 'POST',
            extraHeaders: {
                Authorization: `Bearer ${accessToken.get()}`
            },
            data: params
        })
            .then((res: ApiResponse<Station>) => {
                return res.data;
            })
            .catch((err: ApiResponse<Error>) => {
                return thunkApi.rejectWithValue(err.data);
            });
    }
);

export const postStationSlice = createSlice({
    name: 'postStation',
    initialState: { isLoading: false, contract: null } as postStationState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(postStationThunk.fulfilled, (state, { payload }) => {
            (state.isLoading = false), (state.contract = payload);
        });
    }
});
