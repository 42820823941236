import accessToken from '@/utils/helpers/accessToken.helper';
import request, { ApiResponse } from '@/utils/helpers/apiResponse.helper';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export interface GetUserLogoState {
    isLoading: boolean;
    error: Error | null;
    data: { logo: string };
}

export const getUserLogoThunk = createAsyncThunk<{ logo: string }, void, { rejectValue: Error }>(
    'users/getUserLogo',
    async (params, thunkApi) => {
        return request({
            url: '/api/users/logo',
            method: 'GET',
            extraHeaders: {
                Authorization: `Bearer ${accessToken.get()}`
            }
        })
            .then((res: ApiResponse<{ logo: string }>) => {
                return res.data;
            })
            .catch((err: ApiResponse<Error>) => {
                return thunkApi.rejectWithValue(err.data);
            });
    }
);

export const getUserLogoSlice = createSlice({
    name: 'userLogo',
    initialState: { isLoading: false, error: null, data: { logo: '' } } as GetUserLogoState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getUserLogoThunk.pending, (state) => {
            state.data = {logo: ''};
        });
        builder.addCase(getUserLogoThunk.fulfilled, (state, { payload }) => {
            (state.isLoading = false), (state.error = null), (state.data = payload);
        });
    }
});
