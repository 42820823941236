import StatusIndicators from '@/components/StatusIndicators/StatusIndicators';
import { Link } from 'react-router-dom';
import Logo_EMSOLAR from '../../assets/images/Logo_EMSOLAR.png';
import ClientLayoutController from './ClientLayout.controller';
import styles from './ClientLayout.module.scss';

interface ClientLayoutProps {
    children?: React.ReactNode;
}
const ClientLayout = ({ children }: ClientLayoutProps) => {
    const { logo, onCloseSession } = ClientLayoutController();
    return (
        <>
            <div className={styles.header}>
                <Link to="/">
                    <img className={styles.logo} src={Logo_EMSOLAR} />
                </Link>
                <img className={styles.logo} src={logo ?? ''} style={{ alignSelf: 'center' }} />
                <div className={styles.logoutContainer} onClick={() => onCloseSession()}>
                    Cerrar sesión
                </div>
            </div>
            <StatusIndicators />
            <div className={styles.container}>{children}</div>
        </>
    );
};

export default ClientLayout;
