import { useField } from 'formik';
import styles from './InputDate.module.scss';
interface InputDateProps {
    name: string;
    label?: string;
    [x: string]: any;
}
const InputDate = ({ label, ...props }: InputDateProps) => {
    const [field, meta, helpers] = useField(props.name);
    const { value } = meta;
    const { setValue } = helpers;

    return (
        <>
            <div className={styles.container}>
                {label && <label className={styles.label}>{label}</label>}

                <input
                    type={'date'}
                    className={styles.input}
                    value={value}
                    onChange={(text) => {
                        setValue(text.currentTarget.value);
                    }}
                />
            </div>
        </>
    );
};

export default InputDate;
