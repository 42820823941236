import { useAppDispatch } from '@/index';
import { DocumentType } from '@/models/DocumentTypes';
import { getUserDataThunk } from '@/store/auth/getUserData';
import { RootState } from '@/store/rootReducer';
import {
    getStationDocumentByIdThunk,
    getStationDocumentsByIdThunk,
    setSelectedStationId,
    uploadStationDocumentThunk
} from '@/store/stations/stations.slice';
import { getUserLogoThunk } from '@/store/users/getUserLogo.slice';
import { postUserLogoThunk } from '@/store/users/postUserLogo.slice';
import accessToken from '@/utils/helpers/accessToken.helper';
import request from '@/utils/helpers/apiResponse.helper';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

type TabsType = DocumentType | undefined;

function StationDocumentationController() {
    const dispatch = useAppDispatch();
    const setSelectedStationAction = (selectedStationId?: string) =>
        dispatch(setSelectedStationId(selectedStationId));
    const [selectedTab, setSelectedTab] = useState<TabsType>(undefined);
    const [showUploadFile, setShowUploadFile] = useState<boolean>(false);
    const [isUploadingFile, setIsUploadingFile] = useState<boolean>(false);

    const selectedStationId = useSelector((state: RootState) => state.stations.selectedStationId);
    const logo = useSelector((state: RootState) => state.stations.logo);
    const info = useSelector((state: RootState) => state.stations.info);
    const contract = useSelector((state: RootState) => state.stations.contract);
    const certificates = useSelector((state: RootState) => state.stations.certificates);
    const receipt = useSelector((state: RootState) => state.stations.receipt);

    const { stationId } = useParams();

    useEffect(() => {
        if (stationId) {
            setSelectedStationAction(stationId);
        }
    }, [stationId]);

    const handleDeleteFile = (fileName: string, selTab: string) => {
        if (stationId && selTab) {
            request({
                url: `/api/stations/${stationId}/docs/${selTab}`,
                method: 'DELETE',
                extraHeaders: {
                    Authorization: `Bearer ${accessToken.get()}`
                },
                data: {
                    url: fileName
                }
            }).then((e) => {
                //TODO modal delete message
                toast.success('Se ha eliminado con éxito');
                fetchFiles();
            });
        }
    };

    const fetchFiles = () => {
        if (stationId && selectedTab) {
            if (selectedTab === 'info' || selectedTab === 'certificates') {
                dispatch(
                    getStationDocumentsByIdThunk({
                        station_id: stationId,
                        upload_type: selectedTab
                    })
                );
            } else {
                dispatch(
                    getStationDocumentByIdThunk({
                        station_id: stationId,
                        upload_type: selectedTab
                    })
                );
            }
        }
    };
    useEffect(() => {
        fetchFiles();
    }, [selectedTab]);

    const handleFileInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const file = e.target.files?.[0];
        if (file && selectedTab && selectedStationId) {
            let formData = new FormData();
            formData.append('file', file);
            setIsUploadingFile(true);
            // deprecated -> different endpoints for logo and other files
            /* if (selectedTab === 'logo') {
                await dispatch(postUserLogoThunk({ document: formData })).then(() => {
                    dispatch(getUserLogoThunk());
                });
            } else {
                await dispatch(
                    uploadStationDocumentThunk({
                        station_id: selectedStationId,
                        upload_type: selectedTab,
                        document: formData
                    })
                );
            } */
            await dispatch(
                uploadStationDocumentThunk({
                    station_id: selectedStationId,
                    upload_type: selectedTab,
                    document: formData
                })
            );

            setIsUploadingFile(false);
            setShowUploadFile(false);
            fetchFiles();
        }
    };

    const myUserData = useSelector((state: RootState) => state.auth.getUserData.user);
    const stationData = useSelector((state: RootState) => state.stations.stationData);
    const isClient = myUserData?.role === 'client';
    const isAdmin = myUserData?.role === 'admin';
    const appDispatch = useAppDispatch();

    useEffect(() => {
        appDispatch(getUserDataThunk());
    }, []);

    return {
        selectedTab,
        setSelectedTab,
        showUploadFile,
        setShowUploadFile,
        handleFileInput,
        logo,
        info,
        contract,
        certificates,
        receipt,
        isUploadingFile,
        stationData,
        isClient,
        handleDeleteFile,
        isAdmin
    };
}

export default StationDocumentationController;
