import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import styles from './MyModal.module.scss';

interface MyModalProps {
    isOpen: boolean;
    handleClose: () => void;
    children: React.ReactNode;
    title: string;
}
export const MyModal = ({ isOpen, handleClose, children, title }: MyModalProps) => {
    const modalRef = useRef(null);
    if (isOpen) {
        window.scrollTo(0, 0);
        window.onclick = (event) => {
            if (event.target === modalRef.current) handleClose();
        };
    }

    function noScroll() {
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        if (isOpen) window.addEventListener('scroll', noScroll);

        return () => window.removeEventListener('scroll', noScroll);
    }, [isOpen]);

    return (
        <>
            <div
                id="myModal"
                className={classNames(
                    styles.modal,
                    styles[`modal${isOpen ? '__active' : '__inActive'}`]
                )}
                ref={modalRef}
            >
                <div className={styles.modalContent}>
                    <span className={styles.close} onClick={handleClose}>
                        &times;
                    </span>
                    <div className={styles.title}>{title}</div>
                    {children}
                </div>
            </div>
        </>
    );
};
