.mainContainer {
    display: flex;
    flex-direction: column;
    margin: 0px 70px;
}

.labelInputContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 40%;
    padding: 10px;
}

.label {
    font-size: 22px;
    max-width: 400px;
}

.input {
    width: 200px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #fcfcfc;
    text-align: start;
    padding: 0px 10px;
}

.button {
    color: white;
    background-color: #fe6b03;
    padding: 10px;
    margin-top: 30px;
    border-width: 0px;
    width: 100px;
    text-transform: uppercase;
}

button:hover {
    cursor: pointer;
}

input[type='number'] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.selectInput {
    width: 220px;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #fcfcfc;
    text-align: center;
    padding: 0px 0px;
}

.error {
    color: red;
}