.container {
    background-color: rgb(245, 243, 243);
    min-height: var(--body-height);

    .content {
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 40px;

        .formContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 50px;
            .formContent {
                width: auto;
                display: flex;
                flex-direction: column;
                &__firstGrid {
                    width: 100%;
                    display: grid;
                    grid-template-columns: 2fr 1fr 1fr;
                    gap: 30px;
                    align-self: center;
                }
                &__secondGrid {
                    width: 100%;
                    display: grid;
                    grid-template-columns: 1fr 1fr 2fr;
                    align-self: center;
                    gap: 30px;
                }
            }

            .button {
                width: 150px;
                height: 50px;
            }
        }
    }
}

.containerModal {
    min-width: 1000px;
    min-height: 350px;

    .formContainer {
        margin: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        .formContent {
            display: flex;
            flex-direction: column;
            gap: 20px;
            .tableHeader {
                min-width: 800px;
                display: grid;
                grid-template-columns: 150px 150px 150px 650px 70px;
                grid-template-rows: 50px 10px;
                gap: 15px 30px;
                align-items: start;
                justify-items: center;
                .header {
                    font-weight: bolder;
                    text-align: center;
                }
                .br {
                    width: 100%;
                    height: 2px;
                    background-color: #ddd;
                }
            }
            .tableContent {
                overflow-y: scroll;
                min-width: 800px;
                height: 350px;
                width: max-content;
                display: grid;
                grid-template-columns: 150px 150px 150px 650px 70px;
                grid-template-rows: repeat(auto-fill, 50px);
                gap: 30px;
                align-items: start;
                justify-items: center;
                .element {
                    display: flex;
                    justify-content: flex-start;
                    align-self: flex-start;
                    align-items: center;
                    width: 100%;
                }
            }
        }

        .button {
            width: 200px;
            height: 50px;
            margin-bottom: 20px;
            align-self: center;
        }
        .rates {
            padding: 0 20px;
            width: auto;
            &__title {
                font-weight: bolder;
            }
            &__grid {
                display: grid;
                grid-template-columns: 100px 100px 100px 100px 100px 100px;
                gap: 10px;
            }
        }
        .deleteRow {
            width: 50px;
            height: 50px;
            align-self: center;
            &__icon {
                width: 30px;
                height: 30px;
                color: white;
            }
        }
    }
}
