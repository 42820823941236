.container {
    background-color: rgb(245, 243, 243);
    min-height: var(--body-height);
    display: flex;
    flex-direction: column;

    .content {
        padding: 30px 30px 15px 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        .table {
            width: 100%;
            overflow: hidden;
            overflow-y: scroll;
            height: 500px;
        }
    }

    .button {
        align-self: center;
        width: 350px;
        height: 40px;
    }
}

.contentModal {
    margin: 40px 20px 25px 20px;
    .buttons {
        display: flex;
        flex-direction: row;
        gap: 20px;
    }
}
.tableCell {
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon {
    height: 40px;
    width: 40px;
    object-fit: contain;
}
