import { useAppDispatch } from '@/index';
import { RootState } from '@/store/rootReducer';
import { getStationDevicesByIdThunk, setSelectedStationId } from '@/store/stations/stations.slice';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

function StationDevicesController() {
    const dispatch = useAppDispatch();
    const setSelectedStationAction = (selectedStationId?: string) =>
        dispatch(setSelectedStationId(selectedStationId));
    const { devices, max_pages, current_page } = useSelector(
        (state: RootState) => state.stations.stationDevices
    );

    const { stationId } = useParams();

    const fetchStationDevices = async (id: string) => {
        setSelectedStationAction(id);
        dispatch(
            getStationDevicesByIdThunk({
                station_id: id,
                currentParams: { limit: 10, page: current_page }
            })
        );
    };
    const handleChangePage = (page: number) => {
        if (stationId) {
            dispatch(
                getStationDevicesByIdThunk({
                    station_id: stationId,
                    currentParams: { limit: 10, page: page }
                })
            );
        }
    };

    useEffect(() => {
        if (stationId) {
            fetchStationDevices(stationId);
        }
    }, [stationId]);

    return {
        devices,
        stationId,
        handleChangePage,
        max_pages,
        current_page
    };
}

export default StationDevicesController;
