.mainContainer {
    margin: 0px 80px;
    margin-bottom: 50px;
}

.titleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
}


.title {
    font-size: 22px;
    color: #777;
    margin: 0px;
    font-weight: bold;
}

.newAlarmButton {
    color: white;
    background-color: #fe6b03;
    padding: 10px;
    margin-top: 30px;
    border-width: 0px;
}

.table {
    align-self: center;
    margin: 30px 0px;
}

.tableHeaderRow {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

}

.headerCell {
    flex: 1;
    margin-right: 1px;
    background-color: #fe6b03;
    padding: 10px 0px;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
}



.tableBodyRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    font-size: 14px;
    border: 1px solid #fe6b03;
    border-top: 0px;
}

.bodyCell {
    flex: 1;
    padding: 10px 0px;
    text-align: center;
    padding: 5px;
}

.updateCell {
    flex: 1;
    padding: 10px 0px;
    text-align: center;
    padding: 5px;
}

.updateCell:hover {
    cursor: pointer;
}

.historicTable {
    align-self: center;
    margin: 30px 0px;
    width: 50%;
}

.onOffIcon {
    height: 35px;
}

.editIcon {
    height: 35px;
}


.noStationAlarmsHistoryText {
    align-self: center;
    margin: 40px;
}