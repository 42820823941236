// import { RootState } from '@/store/rootReducer';
// import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import accessToken from '@/utils/helpers/accessToken.helper';

function PublicRouteController() {
    const { state }: any = useLocation();

    // const isAuthenticated = useSelector((rs: RootState) => rs.auth.login.isAuthenticated);
    const isAuthenticated = accessToken.exists();

    return { state, isAuthenticated };
}

export default PublicRouteController;
