import Select from 'react-select';
import { useField } from 'formik';
import customStylesInputSelect from './InputSelect.styles';
import { QueryOption } from './InputSelect.types';
import styles from './InputSelect.module.scss';
import { useState } from 'react';
import EditIcon from '@/assets/icons/pencil-square.svg';
interface InputSelectProps {
    label?: string;
    name: string;
    placeholder?: string;
    onChange?: () => void;
    options: QueryOption[];
    handleDisabled?: boolean;
    disabled?: boolean;
    [x: string]: any;
}
const InputSelect = ({ disabled, label, handleDisabled, ...props }: InputSelectProps) => {
    const [field, meta, helpers] = useField(props.name);
    const { value } = meta;
    const { setValue } = helpers;
    const [disabledOptions, setDisabledOptions] = useState<boolean>(
        handleDisabled === true ? true : false
    );

    return (
        <>
            <div className={styles.container}>
                {label && (
                    <>
                        <label className={styles.label}>
                            <div className={styles.label__text}>{label}</div>

                            {handleDisabled && (
                                <EditIcon
                                    className={styles.label__editIcon}
                                    onClick={() => {
                                        setDisabledOptions(!disabledOptions);
                                    }}
                                />
                            )}
                        </label>
                        <label className={styles.label}>{props.label}</label>
                    </>
                )}

                <Select
                    value={value}
                    options={props.options}
                    onChange={(value: QueryOption) => {
                        if (handleDisabled) setDisabledOptions(!disabledOptions);
                        setValue(value);
                        //props.onChange();
                    }}
                    styles={customStylesInputSelect}
                    isDisabled={disabled || disabledOptions}
                />
            </div>
        </>
    );
};

export default InputSelect;
