import { useAppDispatch } from "@/index";
import { TimeRangeOptions } from "@/models/TimeRangeOptions";
import { RootState } from "@/store/rootReducer";
import { getStationDetailByIdThunk } from "@/store/stations/stations.slice";
import { useState } from "react";
import { useSelector } from "react-redux";

function EconomicPerformanceBarChartController() {
    const dispatch = useAppDispatch()

    const data = useSelector((state: RootState) => state.stations.stationDetail?.economic_performance);
    const selectedStationId = useSelector((state: RootState) => state.stations.selectedStationId);

    const [timeRangeSelectorValue, setTimeRangeSelectorValue] = useState<TimeRangeOptions>('week')


    const onChangeTimeRangeSelectorValue = (value: TimeRangeOptions) => {
        setTimeRangeSelectorValue(value);
        if (selectedStationId) {
            dispatch(getStationDetailByIdThunk({
                station_id: selectedStationId,
                time_range: value,
                data_type: 'economic_performance'
            }))
        }
    }

    return { data, timeRangeSelectorValue, onChangeTimeRangeSelectorValue };
}

export default EconomicPerformanceBarChartController;
