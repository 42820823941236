import * as Yup from 'yup';

export const NewAccountSchema = () => {
    const inputSelect = Yup.object({ value: Yup.string(), label: Yup.string() });

    const obj = {
        provider: inputSelect,
        username: Yup.string().trim().required(),
        password: Yup.string().trim().required()
    };
    let schema = Yup.object(obj);

    return schema;
};
