import * as Yup from 'yup';

export const CreateUserSchema = () => {
    const inputSelect = Yup.object({ value: Yup.string(), label: Yup.string() });

    const obj = {
        username: Yup.string().trim().required(),
        email: Yup.string().email().trim().required(),
        cif: Yup.string().trim().required(),
        maintenance_type: inputSelect
    };
    let schema = Yup.object(obj);

    return schema;
};
