import accessToken from '@/utils/helpers/accessToken.helper';
import request, { ApiRequest, ApiResponse } from '@/utils/helpers/apiResponse.helper';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

export interface UserStation {
    id: string;
    client: string;
    contractedRateByClient: { value: string; label: string };
    contractedRate: { value: string; label: string };
    PS: {
        P1?: string | null;
        P2?: string | null;
        P3?: string | null;
        P4?: string | null;
        P5?: string | null;
        P6?: string | null;
    };
}
export interface patchUserStationsState {
    isLoading: boolean;
    error: Error | null;
    userStation: UserStation | null;
}

export const postUserStationsThunk = createAsyncThunk<
    UserStation,
    { id: string; data: { stations: any[] } },
    { rejectValue: Error }
>('userStations/postUserStations', async (params, thunkApi) => {
    return request({
        url: `/api/users/${params.id}/stations`,
        method: 'POST',
        extraHeaders: {
            Authorization: `Bearer ${accessToken.get()}`
        },
        data: params.data
    } as ApiRequest)
        .then((res: ApiResponse<UserStation>) => {
            toast.success(
                `Agregaste ${params.data.stations.length} ${
                    params.data.stations.length > 1 ? 'instalaciones' : 'instalación'
                }`
            );
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            return thunkApi.rejectWithValue(err.data);
        });
});

export const postUserStationsSlice = createSlice({
    name: 'userStations',
    initialState: { isLoading: false, error: null, userStation: null } as patchUserStationsState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(postUserStationsThunk.fulfilled, (state, { payload }) => {
            (state.isLoading = false), (state.error = null), (state.userStation = payload);
        });
    }
});
