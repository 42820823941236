import { useAppDispatch } from '@/index';
import { RootState } from '@/store/rootReducer';
import { getStationDataByIdThunk } from '@/store/stations/stations.slice';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

function StationNavigationHeaderController() {
    const dispatch = useAppDispatch();
    const selectedStationId = useSelector((state: RootState) => state.stations.selectedStationId);
    const stationData = useSelector((state: RootState) => state.stations.stationData);

    const { stationId } = useParams();

    const fetchStationData = async (id: string) => {
        dispatch(getStationDataByIdThunk({ station_id: id }));
    };

    useEffect(() => {
        if (stationId) {
            fetchStationData(stationId);
        }
    }, [stationId]);

    return {
        selectedStationId,
        stationData
    };
}

export default StationNavigationHeaderController;
