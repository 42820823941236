//import { ContractData } from '@/models/contract.model';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import request, { ApiResponse } from '@/utils/helpers/apiResponse.helper';
//import { GetFilterQueryParams } from './getContractEvaluations.slice';
import accessToken from '@/utils/helpers/accessToken.helper';
import { toast } from 'react-toastify';
import { stat } from 'fs';

export const Role: { client: string; technician: string; admin: string } = {
    client: 'Cliente',
    technician: 'Técnico',
    admin: 'Administrador'
};
interface UserData {
    cif: string;
    email: string;
    maintenance_type: 'Ninguno' | 'Standard' | 'Premium';
    role: typeof Role;
    username: string;
}

export interface getUsersState {
    isLoading: boolean;
    error: Error | null;
    current_page: number;
    max_pages: number;
    usersList: UserData[] | null;
}

export type UserFilter = {
    username?: string;
};

export const getUsersThunk = createAsyncThunk<
    { current_page: number; max_pages: number; users: UserData[] | null },
    UserFilter,
    { rejectValue: Error }
>('users/getUsers', async (params, thunkApi) => {
    var requestUrl = `/api/users`;

    return request({
        url: requestUrl,
        method: 'GET',
        extraHeaders: {
            Authorization: `Bearer ${accessToken.get()}`
        },
        params: new URLSearchParams(params)
    })
        .then(
            (
                res: ApiResponse<{
                    current_page: number;
                    max_pages: number;
                    users: UserData[] | null;
                }>
            ) => {
                return res.data;
            }
        )
        .catch((err: ApiResponse<Error>) => {
            toast.error('No se encontraron usuarios');
            return thunkApi.rejectWithValue(err.data);
        });
});

export const getUsersSlice = createSlice({
    name: 'users',
    initialState: {
        isLoading: false,
        error: null,
        current_page: 1,
        max_pages: 1,
        usersList: []
    } as getUsersState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getUsersThunk.fulfilled, (state, { payload }) => {
            (state.isLoading = false),
                (state.error = null),
                (state.usersList = payload.users),
                (state.current_page = payload.current_page),
                (state.max_pages = payload.max_pages);
        });
    }
});
