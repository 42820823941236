import accessToken from '@/utils/helpers/accessToken.helper';
import request, { ApiRequest, ApiResponse } from '@/utils/helpers/apiResponse.helper';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { UserStation } from './getUserStations.slice';

export interface getUsersStationsState {
    isLoading: boolean;
    error: Error | null;
    usersStations: UserStation[] | null;
    current_page: number;
    max_pages: number;
}

export const getUsersStationsThunk = createAsyncThunk<
    {
        current_page: number;
        max_pages: number;
        stations: UserStation[] | null;
    },
    { user_id?: string },
    { rejectValue: Error }
>('userStations/getUsersStations', async (params, thunkApi) => {
    return request({
        url: `/api/users/stations`,
        method: 'GET',
        extraHeaders: {
            Authorization: `Bearer ${accessToken.get()}`
        },
        params: new URLSearchParams(params)
    } as ApiRequest)
        .then(
            (
                res: ApiResponse<{
                    current_page: number;
                    max_pages: number;
                    stations: UserStation[] | null;
                }>
            ) => {
                return res.data;
            }
        )
        .catch((err: ApiResponse<Error>) => {
            toast.error('No se encontraron instalaciones');
            return thunkApi.rejectWithValue(err.data);
        });
});

export const getUsersStationsSlice = createSlice({
    name: 'usersStations',
    initialState: {
        isLoading: false,
        error: null,
        usersStations: [],
        current_page: 1,
        max_pages: 1
    } as getUsersStationsState,
    reducers: {
        remove: (state) => {
            state.usersStations = null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getUsersStationsThunk.fulfilled, (state, { payload }) => {
            (state.isLoading = false),
                (state.error = null),
                (state.usersStations = payload.stations),
                (state.current_page = payload.current_page),
                (state.max_pages = payload.max_pages);
        });
    }
});
