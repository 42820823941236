import { useAppDispatch } from '@/index';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getStationByIdEditThunk, updateStationByIdThunk } from '@/store/stations/stations.slice';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/rootReducer';
import { StationData, TariffData } from '@/models/StationData';
import { PricingType } from '@/store/userStations/getUserStations.slice';
import { currentDate } from '@/utils/helpers/date.helper';

export interface TariffDataFormik {
    pricing_type: { value: string; label: string };
    tariff: { value: string; label: string };
    date: string;
    tiers: {
        P1?: string | null;
        P2?: string | null;
        P3?: string | null;
        P4?: string | null;
        P5?: string | null;
        P6?: string | null;
    };
}

const EditStationController = () => {
    const params = useParams();
    const appDispatch = useAppDispatch();
    const station = useSelector((state: RootState) => state.stations.stationData);
    const navigate = useNavigate();

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [dataTariff, setDataTariff] = useState<any[] | null>(null);

    const [initialValues, setInitialValues] = useState({
        name: '',
        address: '',
        cost: 0,
        start_time: '',
        latitude: 0,
        longitude: 0
    });

    const generateStructure = (): { data: TariffDataFormik[] } => {
        //@ts-ignore
        const aux: TariffData[] = [
            {
                date: currentDate(),
                pricing_type: '',
                tariff: '',
                tiers: {
                    P1: '0',
                    P2: '0',
                    P3: '0',
                    P4: '0',
                    P5: '0',
                    P6: '0'
                }
            }
        ];

        const item: any =
            dataTariff && dataTariff.length ? dataTariff : station?.tariff_data ?? aux;
        //TODO conectar con el api de manera correcta

        const newArr: TariffDataFormik[] = item.map((element: TariffData, i: number) => {
            return {
                ...element,
                date: element.date,
                pricing_type: {
                    value: element?.pricing_type, //@ts-ignore
                    label: PricingType[element?.pricing_type]
                },
                tariff: { value: element?.tariff, label: element?.tariff },
                tiers: {
                    ...element?.tiers
                }
            };
        });

        return { data: newArr };
    };

    const handleSaveTariff = ({ data }: { data: any[] }) => {
        const dataValues = data.map((e, i) => {
            return {
                pricing_type: e.pricing_type.value,
                tariff: e.tariff.value,
                tiers: { ...e.tiers },
                date: e.date
            };
        });

        setDataTariff(dataValues);
        setOpenModal(false);
    };

    const handleOnSubmit = (values: any, actions: any) => {
        const currenTariffData =
            dataTariff && dataTariff.length ? dataTariff : station?.tariff_data ?? [];

        const dataSubmit = {
            station_id: params.id ?? '',
            station: { ...(values as StationData), tariff_data: [...currenTariffData] }
        };
        appDispatch(updateStationByIdThunk(dataSubmit));
        navigate('/');
    };
    useEffect(() => {
        if (station && params?.id) {
            setInitialValues({
                name: station.name ?? '',
                address: station.address ?? '',
                cost: station.cost ?? 0,
                start_time: new Date(station.start_time).toISOString().split('T')[0] ?? '',
                latitude: station.latitude ?? 0,
                longitude: station.longitude ?? 0
            });
        }
    }, [station]);
    useEffect(() => {
        if (params?.id) appDispatch(getStationByIdEditThunk({ station_id: params.id }));
    }, []);
    return {
        initialValues,
        handleOnSubmit,
        setOpenModal,
        openModal,
        handleSaveTariff,
        generateStructure,
        station
    };
};

export default EditStationController;
