import { useField } from 'formik';
import styles from './InputTextV2.module.scss';

interface InputTextV2Props {
    label: string;
    label2?: string;
    name: string;
    type?: 'text' | 'number' | 'date';
    placeholder?: string;
    handleDisabled?: boolean;
    [x: string]: any;
}
const InputTextV2 = ({ label, label2, handleDisabled, ...props }: InputTextV2Props) => {
    const [field, meta] = useField(props.name);

    return (
        <>
            <div className={styles.container}>
                <label className={styles.label}>
                    <div className={styles.label__text}>
                        {label}
                        {meta.touched && meta.error && <div style={{ color: 'red' }}>*</div>}
                    </div>
                </label>

                <input {...field} {...props} className={styles.input} />
                <label className={styles.label}>
                    <div className={styles.label__text}>{label2}</div>
                </label>
            </div>
        </>
    );
};

export default InputTextV2;
