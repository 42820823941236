import { combineReducers } from 'redux';
import { getUsersStationsSlice } from './getUsersStations.slice';
import { getUserStationSlice } from './getUserStation.slice';
import { getUserStationsSlice } from './getUserStations.slice';
import { patchNoUserStationSlice } from './patchNoUserStation.slice';
import { patchUserStationSlice } from './patchUserStation.slice';

export const UserStationsReducer = combineReducers({
    getUserStations: getUserStationsSlice.reducer,
    getUsersStations: getUsersStationsSlice.reducer,
    getUserStation: getUserStationSlice.reducer,
    patchUserStation: patchUserStationSlice.reducer,
    patchNoUserStation: patchUserStationSlice.reducer
});
export const UserStationsActions = {
    ...getUserStationSlice.actions,
    ...getUserStationsSlice.actions,
    ...getUserStationsSlice.actions,
    ...patchUserStationSlice.actions,
    ...patchNoUserStationSlice.actions
};
