.container {
    background-color: #001533;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 90vh;
    border-width: 10px;
    border-color: aqua;
}

.title {
    color: white;
    font-size: 60px;
}
.button {
    color: white;
    background-color: var(--color-primary);
    padding: 10px;
    margin-top: 30px;
    border-width: 0px;
}

.contentModal {
    margin: 20px;
    width: 400px;
    .buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
}
