import { BarChart, Bar, LabelList, XAxis, Tooltip, ResponsiveContainer } from 'recharts';
import Spinner from '../Spinner/Spinner';
import TimeRangeSelector from '../TimeRangeSelector/TimeRangeSelector';
import EconomicPerformanceBarChartController from './EconomicPerformanceBarChart.controller';

import styles from './EconomicPerformanceBarChart.module.scss';

const EconomicPerformanceBarChart = () => {
    const { data, timeRangeSelectorValue, onChangeTimeRangeSelectorValue } =
        EconomicPerformanceBarChartController();

    if (data === undefined) {
        return (
            <div
                className={styles.stationDetailsItem}
                style={{
                    justifyContent: 'center'
                }}
            >
                <Spinner isLoading={true} />
            </div>
        );
    }

    return (
        <div className={styles.stationDetailsItem}>
            <div className={styles.stationDetailsItemTitleRow}>
                <div className={styles.stationDetailsItemTitle}>
                    Rendimiento económico producido €
                </div>
                <TimeRangeSelector
                    value={timeRangeSelectorValue}
                    onSelected={onChangeTimeRangeSelectorValue}
                />
            </div>
            <ResponsiveContainer width="100%" height={400}>
                <BarChart data={data} margin={{ top: 40 }}>
                    <XAxis dataKey="key" />
                    <Bar dataKey="value" fill="#e66c23" name="Producido">
                        <LabelList dataKey="value" position="top" />
                    </Bar>
                    <Tooltip />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default EconomicPerformanceBarChart;
