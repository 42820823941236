import accessToken from '@/utils/helpers/accessToken.helper';
import request, { ApiResponse } from '@/utils/helpers/apiResponse.helper';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

export interface UserData {
    role: 'client' | 'technician' | 'admin';
    username: string;
    cif: string;
    email: string;
    maintenance_type?: { value: string; label: string };
    password?: string;
}
export interface GetUserState {
    isLoading: boolean;
    error: Error | null;
    user: UserData | null;
}

export const getUserByIdThunk = createAsyncThunk<UserData, string, { rejectValue: Error }>(
    'users/getUserById',
    async (params, thunkApi) => {
        return request({
            url: `/api/users/${params}`,
            method: 'GET',
            extraHeaders: {
                Authorization: `Bearer ${accessToken.get()}`
            }
        })
            .then((res: ApiResponse<UserData>) => {
                return res.data;
            })
            .catch((err: ApiResponse<Error>) => {
                toast.error('Usuario no encontrado');
                return thunkApi.rejectWithValue(err.data);
            });
    }
);

export const getUserByIdSlice = createSlice({
    name: 'userById',
    initialState: { isLoading: false, error: null, user: null } as GetUserState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getUserByIdThunk.fulfilled, (state, { payload }) => {
            (state.isLoading = false), (state.error = null), (state.user = payload);
        });
    }
});
