import { RootState } from "@/store/rootReducer";
import { useSelector } from "react-redux";



function EnergyMapController() {
    const energy_map = useSelector((state: RootState) => state.stations.stationDetail?.energy_map);

    return { energy_map }

}

export default EnergyMapController;
