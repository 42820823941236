.stationDataContainer {
    display: flex;
    justify-content: center;
    margin: 0px 60px;
    margin-bottom: 100px;
}

.stationGeneralDataContainer {
    align-self: flex-start;
    padding: 20px;
    width: -webkit-fill-available;
}

.stationGeneralDataText {
    font-size: 22px;
    color: #777;
    display: flex;
    align-items: center;
}

.stationGeneralDataIcon {
    height: 35px;
    margin-right: 5px;
}

.stationDataItem {
    display: flex;
    flex: 1;
    flex-direction: column;
    border: 2px solid #fe6b03;
    margin: 20px 20px;
    padding: 25px;
    align-items: center;
    justify-content: space-between;
}

.stationDataLeftContainer {
    flex: 1;
}

.stationDataLeftRowContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
}

.title {
    font-size: 28px;
    color: #777;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0px;
    text-align: center;
    margin-bottom: 30px;
}

.icon {
    height: 130px;
}

.bigNumber {
    font-size: 30px;
    color: #777;
    margin: 0px;
    text-align: center;
    text-transform: uppercase;
}

.image {
    flex: 1;
    height: 400px;
    // width: 700px;
    object-fit: contain;
}

.amortizedCapitalRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.amortizedCapitalItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 30px;
}

.amortizedCapitalLegendRow {
    display: flex;
    flex-direction: row;
    width: -webkit-fill-available;
    margin: 5px 0px;
    align-items: center;
}

.amortizedCapitalLegendChip {
    height: 5px;
    width: 20px;
    padding: 5px;
    background-color: #fe6b03;
    border-radius: 20px;
    margin-right: 5px;
}

.amortizedCapitalLegendText {
    color: #777;
    text-transform: uppercase;
    font-size: 15px;
}

.serviceTypeContainer {
    display: flex;
    flex-direction: row;
}

.premiumServiceContainer {
    color: #fe6b03;
    font-weight: bold;
    margin-right: 10px;
    border: 2px solid #fe6b03;
    padding: 5px 15px;
}

.basicServiceContainer {
    border: 2px solid #fe6b03;
    padding: 5px 15px;
    margin: 0px 5px;
}
.footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &__text {
        margin: 0 0 40px 0;
        width: 60%;
        text-align: center;
    }
}
