.table {
    width: 100%;
    border-spacing: 0px 0px;
}

.tableHeader {
    background-color: #fe6b03;
    color: white;
    padding: 20px 50px;
    font-size: 20px;
    border-right: 4px solid white;
    text-transform: uppercase;
}

.tableCell {
    border-bottom: 1px solid lightgrey;
    align-self: center;
    margin: 0px;
    text-align: center;
    align-items: center;
    padding: 10px;
    font-size: 20px;
    justify-content: center;
}
.icon {
    height: 30px;
    cursor: pointer;
}
.icon:hover {
    opacity: 0.7;
}

.psContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr auto;
    justify-content: center;
    align-items: center;
    align-self: center;
    gap: 20px;

    .psItem {
        align-self: center;
        width: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        &__section {
            width: 90px;
            align-self: center;
            display: flex;
            justify-content: start;
        }
    }
}
