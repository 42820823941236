import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '@/index';
import { RootState } from '@/store/rootReducer';
import accessToken from '@/utils/helpers/accessToken.helper';
import { getUserLogoThunk } from '@/store/users/getUserLogo.slice';

function ClientLayoutController() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { logo } = useSelector((state: RootState) => state.users.getUserLogo.data);
    const isAuthenticated = useSelector((state: RootState) => state.auth.login.isAuthenticated);

    const onCloseSession = () => {
        accessToken.remove();
        navigate('/login', {
            replace: true
        });
        location.reload();
    };

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login', {
                replace: true
            });
        }
    });

    useEffect(() => {
        dispatch(getUserLogoThunk());
    }, []);

    return { logo, onCloseSession };
}

export default ClientLayoutController;
