.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 350px;
    gap: 10px;

    .label {
        width: 100%;
        display: flex;
        justify-content: start;
        align-items: flex-start;
        font-weight: bolder;
    }
    .input {
        border: none;
        outline: none;
        width: 100%;
        height: 45px;
        border-radius: 5px;
        padding-left: 10px;
    }
}
