.stationDetailsItem {
    display: flex;
    flex-direction: column;
    flex: 1;
    border: 2px solid #fe6b03;
    margin: 5px;
    padding: 5px;
    align-items: center;
    justify-content: flex-end;
    padding: 30px;
    height: 450px;
}

.stationDetailsItemTitleRow {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: baseline;
    width: -webkit-fill-available;
}

.stationDetailsItemTitle {
    font-size: 22px;
    color: #777777;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    text-align: center;

}