import { useAppDispatch } from "@/index";
import { TimeRangeOptions } from "@/models/TimeRangeOptions";
import { RootState } from "@/store/rootReducer";
import { getStationDetailByIdThunk } from "@/store/stations/stations.slice";
import { useState } from "react";
import { useSelector } from "react-redux";


type ProductedConsumedEnergyChartData = {
    production: number;
    consumption: number;
    key: string;
}

function ProductedConsumedEnergyChartController() {
    const dispatch = useAppDispatch()

    const data = useSelector((state: RootState) => state.stations.stationDetail?.production_vs_consumption);
    const selectedStationId = useSelector((state: RootState) => state.stations.selectedStationId);

    const [timeRangeSelectorValue, setTimeRangeSelectorValue] = useState<TimeRangeOptions>('week')


    const onChangeTimeRangeSelectorValue = (value: TimeRangeOptions) => {
        setTimeRangeSelectorValue(value);
        if (selectedStationId) {
            dispatch(getStationDetailByIdThunk({
                station_id: selectedStationId,
                time_range: value,
                data_type: 'production_vs_consumption'
            }))
        }
    }

    const parsedData: ProductedConsumedEnergyChartData[] | undefined =  data ? data.consumption.map((item, index) => ({
            consumption: item.value,
            key: item.key,
            production: data.production[index].value
        })) : undefined;
   

    return { parsedData, timeRangeSelectorValue, onChangeTimeRangeSelectorValue };
}

export default ProductedConsumedEnergyChartController;
