import accessToken from '@/utils/helpers/accessToken.helper';
import request, { ApiRequest, ApiResponse } from '@/utils/helpers/apiResponse.helper';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

export const PricingType = {
    fixed: 'Importe fijo',
    pool: 'Cliente a pool'
};

export interface UserStation {
    id: string;
    client: string;
    name: string;
    pricing_type: string;
    tariff: string;
    tiers: {
        P1?: string | null;
        P2?: string | null;
        P3?: string | null;
        P4?: string | null;
        P5?: string | null;
        P6?: string | null;
    };
    start_time?: string;
    end_time?: string;
}
export interface getUserStationsState {
    isLoading: boolean;
    error: Error | null;
    current_page: number;
    max_pages: number;
    userStations: UserStation[] | null;
}

export const getUserStationsThunk = createAsyncThunk<
    { current_page: number; max_pages: number; stations: UserStation[] | null },
    { id: string; currentParams: { limit?: any; page?: any } },
    { rejectValue: Error }
>('userStations/getUserStations', async (params, thunkApi) => {
    return request({
        url: `/api/users/${params.id}/stations`,
        method: 'GET',
        extraHeaders: {
            Authorization: `Bearer ${accessToken.get()}`
        },
        params: new URLSearchParams(params.currentParams)
    } as ApiRequest)
        .then(
            (
                res: ApiResponse<{
                    current_page: number;
                    max_pages: number;
                    stations: UserStation[] | null;
                }>
            ) => {
                return res.data;
            }
        )
        .catch((err: ApiResponse<Error>) => {
            toast.error('No se encontraron instalaciones');
            return thunkApi.rejectWithValue(err.data);
        });
});

export const getUserStationsSlice = createSlice({
    name: 'userStations',
    initialState: {
        isLoading: false,
        error: null,
        userStations: [],
        current_page: 1,
        max_pages: 1
    } as getUserStationsState,
    reducers: {
        remove: (state) => {
            state.userStations = null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getUserStationsThunk.fulfilled, (state, { payload }) => {
            (state.isLoading = false),
                (state.error = null),
                (state.userStations = payload.stations),
                (state.current_page = payload.current_page),
                (state.max_pages = payload.max_pages);
        });
    }
});
