.container {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border: 1px solid var(--color-primary);
    background-color: white;
    border-radius: 5px;
    .input {
        width: 60%;
        height: 90%;
        border: none;
        outline: none;
        margin: 0 0 0 10px;
        color: var(--color-primary);
    }
    .input::placeholder {
        color: var(--color-primary);
    }
    .select {
        width: 30%;
        height: 100%;
    }
    .containerIcon {
        width: 10%;
        background-color: var(--color-primary);
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .icon {
            margin: 15px;
            min-width: 20px;
        }
    }
}
