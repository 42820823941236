.loginContainer {
    background-color: #001533;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 90vh;
    border-width: 10px;
    border-color: aqua;
}

.title {
    color: white;
    font-size: 60px;
}

.error {
    color: red;
    font-size: 20px;
}

.button {
    color: white;
    background-color: var(--color-primary);
    padding: 10px;
    margin-top: 30px;
    border-width: 0px;
}

.recoverPassword {
    display: inline-block;
    position: relative;
    color: rgb(233, 232, 232);
    text-decoration: none;
    font-size: 20px;
}
.recoverPassword:after {
    content: '';
    display: block;
    margin: auto;
    height: 1px;
    width: 0px;
    transition: all 0.8s;
}
.recoverPassword:hover:after {
    width: 100%;
    background: rgb(206, 206, 206);
}
button:hover {
    cursor: pointer;
}
