.mainContainer {
    margin: 0px 80px;
    width: 70vw;
}

.title {
    font-size: 22px;
    color: #777;
    margin: 0px;
    font-weight: bold;
    margin-bottom: 20px;
}

.invertersHeaderContainer {
    display: flex;
    justify-content: space-between;
}

.headerCell {
    flex: 1;
    margin-right: 1px;
    background-color: #fe6b03;
    padding: 10px 0px;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 14px;
}

.bodyRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid #fe6b03;
    border-top: 0px;
}

.bodyCell {
    flex: 1;
    margin-right: 1px;
    background-color: #fff;
    padding: 10px 0px;
    text-align: center;
    color: #777;
    font-size: 16px;
}


.inverterContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border: 1px solid #fe6b03;
    border-top: 0px;
    text-transform: uppercase;
    font-size: 20px;
    text-align: center;
    padding: 10px 0px;
}

.inverterContainer:hover {
    cursor: pointer;
}

.icon {
    position: relative;
    left: 15vw;
    height: 30px;
    width: 30px;
    object-fit: contain;
}