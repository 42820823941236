import { useLocation } from 'react-router-dom';
import accessToken from '@/utils/helpers/accessToken.helper';

function PrivateRouteController() {
    const isAuthenticated = accessToken.exists()

    const location = useLocation();

    return { isAuthenticated, location };
}

export default PrivateRouteController;
