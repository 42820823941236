import AddNewAccount from '@/pages/Admin/AddNewAccount/AddNewAccount';
import CreateStation from '@/pages/Admin/CreateStation/CreateStation';
import CreateUser from '@/pages/Admin/CreateUser/CreateUser';
import EditStation from '@/pages/Admin/EditStation/EditStation';
import EditUser from '@/pages/Admin/EditUser/EditUser';
import HomeAdmin from '@/pages/Admin/HomeAdmin/HomeAdmin';
import ManageStationsByUser from '@/pages/Admin/ManageUserStations/ManageUserStations';
import ManageUserStations from '@/pages/Admin/ManageUserStations/ManageUserStations';
import UserManagement from '@/pages/Admin/UserManagement/UserManagement';
import UserStations from '@/pages/Admin/UserStations/UserStations';
import StationDocumentation from '@/pages/StationDocumentation/StationDocumentation';
import PrivateRoute from '../PrivateRoute';
export const AdminRoutes = [
    {
        path: '/*',
        element: (
            <PrivateRoute>
                <HomeAdmin />
            </PrivateRoute>
        )
    },
    {
        path: '/user-management',
        element: (
            <PrivateRoute>
                <UserManagement />
            </PrivateRoute>
        )
    },
    {
        path: '/create-user/:role',
        element: (
            <PrivateRoute>
                <CreateUser />
            </PrivateRoute>
        )
    },
    {
        path: '/edit-user/:id',
        element: (
            <PrivateRoute>
                <EditUser />
            </PrivateRoute>
        )
    },
    {
        path: '/create-station/:id',
        element: (
            <PrivateRoute>
                <CreateStation />
            </PrivateRoute>
        )
    },
    {
        path: '/edit-station/:id',
        element: (
            <PrivateRoute>
                <EditStation />
            </PrivateRoute>
        )
    },
    {
        path: '/add-new-account',
        element: (
            <PrivateRoute>
                <AddNewAccount />
            </PrivateRoute>
        )
    },
    {
        path: '/manage-stations/:id',
        element: (
            <PrivateRoute>
                <ManageStationsByUser />
            </PrivateRoute>
        )
    },
    {
        path: '/user-stations/:id',
        element: (
            <PrivateRoute>
                <UserStations />
            </PrivateRoute>
        )
    },
    {
        path: '/manage-user-stations/:id',
        element: (
            <PrivateRoute>
                <ManageUserStations />
            </PrivateRoute>
        )
    },
    {
        path: '/documentation/:stationId',
        element: (
            <PrivateRoute>
                {/**@ts-ignore */}
                <StationDocumentation />
            </PrivateRoute>
        )
    }
];

export default AdminRoutes;
