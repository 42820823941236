import * as Yup from 'yup';

export const CreateStationSchema = () => {
    const inputSelect = Yup.object({ value: Yup.string(), label: Yup.string() });

    const obj = {
        accesURL: Yup.string().trim().required(),
        accesUser: Yup.string().trim().required(),
        password: Yup.string().trim().required(),
        contractedRate: inputSelect
    };
    let schema = Yup.object(obj);

    return schema;
};
