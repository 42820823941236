.container {
    display: flex;
    flex-direction: row;
    width: 100%;
    color: white;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.columnContainer {
    flex: 1;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    padding: 20px;
}

.title {
    font-size: 25px;
    text-transform: uppercase;
    color: #d2d7dd;
    font-weight: bold;
    margin: 0px;
    margin-bottom: 30px;
    text-align: center;
}

.elementContainer {
    display: flex;
    flex-direction: row;
    align-self: center;
    margin-left: 20px;
}

.textContainer {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    align-items: flex-start;
    justify-content: center;
}

.icon {
    height: 50px;
    width: 50px;
    object-fit: contain;
    align-self: center;
}

.textIndicator {
    font-size: 40px;
    padding-right: 5px;
    font-weight: bold;
}

.economicImpactContainer {
    align-self: center;
    background-color: #fe6b03;
    width: fit-content;
    padding: 20px;
}

.economicImpactTitle {
    font-size: 22px;
    text-transform: uppercase;
}

.economicImpactBoldText {
    font-size: 35px;
    font-weight: bold;
    margin-right: 10px;
    text-transform: uppercase;
}

.weatherElementContainer {
    display: flex;
    flex-direction: row;
    align-self: center;
    margin-left: 20px;
    justify-content: space-between;
    align-items: flex-end;
}

.weatherTextIndicator {
    font-size: 22px;
    font-weight: bold;
    width: 170px;
    text-align: center;
    align-self: center;
}

.dayIndicator {
    margin-left: -5px;
    text-align: center;
    background-color: #224f79;
    padding: 5px;
    font-size: 12px;
    width: 60px;
}

.weatherStatus {
    padding-left: 90px;
}
