.selectedValueContainer {
    display: flex;
    padding: 10px 20px;
    background-color: #696969;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
    justify-content: space-between;
    align-items: center;
    max-height: 40px;
    white-space: nowrap;
}

.selectedValueContainer:hover {
    cursor: pointer;
}

.icon {
    width: 20px;
    height: 20px;
    align-self: center;
    margin-left: 20px;
}

.optionsContainer {
    position: absolute;
    z-index: 100;
}

.optionItem {
    text-transform: uppercase;
    background-color: #696969;
    font-size: 20px;
    padding: 10px 20px;
    color: white;
}

.optionItem:hover {
    cursor: pointer;
}