import Cabecera from '../../assets/images/cabecera.jpg';

import styles from './StatusIndicators.module.scss';

import BuildingIcon from '../../assets/icons/BuildingIcon.png';
import GeneratedPowerIcon from '../../assets/icons/GeneratedPowerIcon.png';
import PlugIcon from '../../assets/icons/PlugIcon.png';
import PowerInstalledIcon from '../../assets/icons/PowerInstalledIcon.png';
import TreeIcon from '../../assets/icons/TreeIcon.png';
import CarIcon from '../../assets/icons/CarIcon.png';
import StatusIndicatorController from './StatusIndicators.controller';
import TimeIcon from '../TimeIcon/TimeIcon';
import { Weather } from '@/models/StationResume';

const StatusIndicators = () => {
    const { selectedStationResume, parseStatusText, convertToEuropean } =
        StatusIndicatorController();


    if (selectedStationResume === undefined) {
        return <div className={styles.container}></div>;
    }

    const {
        total_capacity,
        total_accumulated_energy,
        month_accumulated_energy,
        environmental,
        weather,
        economic_impact
    } = selectedStationResume;

    const renderWeatherElement = (
        label: string,
        weather: {
            status: Weather;
            performance: number;
        }
    ) => {
        console.log("WEATHER:", weather); 
        return (
            <div>
                <div className={styles.weatherElementContainer}>
                    <TimeIcon
                        status={weather?.status ?? 'unknown'}
                        // status="clear"
                        className={styles.icon}
                    />
                    <div className={styles.weatherTextIndicator}>
                        {weather?.performance}
                    </div>
                    rendimiento estimado
                    <div className={styles.dayIndicator}>{label}</div>
                </div>
                <div className={styles.weatherStatus}>
                    {parseStatusText(weather?.status ?? 'unknown')}
                </div>
            </div>
        );
    };

    return (
        <div
            className={styles.container}
            style={{
                background: `url(${Cabecera})`
            }}
        >
            <div className={styles.columnContainer}>
                <p className={styles.title}>Indicadores de planta</p>
                <div className={styles.elementContainer}>
                    <img className={styles.icon} src={PowerInstalledIcon} />
                    <div className={styles.textContainer}>
                        <div>
                            <span className={styles.textIndicator}>
                                {convertToEuropean(total_capacity)}
                            </span>
                            kW
                        </div>
                        <div>Potencia instalada</div>
                    </div>
                </div>
                <div className={styles.elementContainer}>
                    <img className={styles.icon} src={GeneratedPowerIcon} />
                    <div className={styles.textContainer}>
                        <div>
                            <span className={styles.textIndicator}>
                                {convertToEuropean(total_accumulated_energy)}
                            </span>
                            kW
                        </div>
                        <div>Potencia total generada</div>
                    </div>
                </div>
                <div className={styles.elementContainer}>
                    <img className={styles.icon} src={PlugIcon} />
                    <div className={styles.textContainer}>
                        <div>
                            <span className={styles.textIndicator}>
                                {convertToEuropean(month_accumulated_energy)}
                            </span>
                            kW
                        </div>
                        <div>Generados promedio último mes</div>
                    </div>
                </div>
            </div>

            <div className={styles.columnContainer}>
                <p className={styles.title}>Impacto Medioambiental</p>
                <div className={styles.elementContainer}>
                    <img className={styles.icon} src={TreeIcon} />

                    <div className={styles.textContainer}>
                        <div>
                            <span className={styles.textIndicator}>
                                {convertToEuropean(environmental?.reduction_total_tree ?? 0)}{' '}
                            </span>
                            árboles
                        </div>
                        <div>
                            Equivale a{' '}
                            {convertToEuropean(environmental?.reduction_total_tree ?? 0)}{' '}
                            árboles plantados
                        </div>
                    </div>
                </div>
                <div className={styles.elementContainer}>
                    <img className={styles.icon} src={BuildingIcon} />

                    <div className={styles.textContainer}>
                        <div>
                            <span className={styles.textIndicator}>
                                {convertToEuropean(environmental?.reduction_total_co2 ?? 0)}
                            </span>
                            kg
                        </div>
                        <div>De CO2 evitado</div>
                    </div>
                </div>
                <div className={styles.elementContainer}>
                    <img className={styles.icon} src={CarIcon} />
                    <div className={styles.textContainer}>
                        <div>
                            <span className={styles.textIndicator}>
                                {convertToEuropean(environmental?.car_km_equivalent ?? 0)}
                            </span>
                            km
                        </div>
                        <div>
                            Has evitado la contaminación de{' '}
                            {convertToEuropean(environmental?.car_km_equivalent ?? 0)} km en
                            coche
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.columnContainer}>
                <p className={styles.title}>Previsión climatológica</p>
                {renderWeatherElement('Hoy', weather?.today)}
                {renderWeatherElement('Mañana', weather?.tomorrow)}
                {renderWeatherElement('Pasado\nMañana', weather?.after_tomorrow)}
            </div>
            <div className={styles.columnContainer}>
                <div className={styles.economicImpactContainer}>
                    <div className={styles.economicImpactTitle}>Impacto económico</div>
                    <p>
                        <span className={styles.economicImpactBoldText}>
                            {convertToEuropean(economic_impact.total_generated)}€
                        </span>
                        AHORRO GENERADO
                    </p>
                    <div>
                        <span className={styles.economicImpactBoldText}>
                            {economic_impact?.amortized_investment.toFixed(2)}{'%'}
                        </span>
                        INVERSIÓN AMORTIZADA
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StatusIndicators;
