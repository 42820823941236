import Loader from '@/components/Loader/Loader';
import userRole from '@/utils/helpers/userRole.helper';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/rootReducer';
import AdminLayout from './AdminLayout/AdminLayout';
import ClientLayout from './ClientLayout/ClientLayout';
import GuestUserLayout from './GuestUserLayout/GuestUserLayout';

interface LayoutProps {
    children?: React.ReactNode;
}
export enum UserRoleTypes {
    CLIENT = 'client',
    TECHNICIAN = 'technician',
    ADMIN = 'admin',
    GUEST = 'guest'
}

const Layout = ({ children }: LayoutProps) => {
    const isLoading = useSelector((state: RootState) => state.loader.setLoader.isLoading);
    const isAuthenticated = useSelector((state: RootState) => state.auth.login.isAuthenticated);

    const userType = userRole.get();

    const HandlerRenderLayout = {
        [UserRoleTypes.ADMIN]: <AdminLayout>{children}</AdminLayout>,
        [UserRoleTypes.TECHNICIAN]: <AdminLayout>{children}</AdminLayout>,
        [UserRoleTypes.CLIENT]: <ClientLayout>{children}</ClientLayout>,
        [UserRoleTypes.GUEST]: <GuestUserLayout>{children}</GuestUserLayout>
    };
    const RenderLayout = () => {
        if (userType && isAuthenticated) {
            /*@ts-ignore */
            return HandlerRenderLayout[userType];
        } else {
            return HandlerRenderLayout[UserRoleTypes.GUEST];
        }
    };
    return (
        <>
            <Loader isLoading={isLoading} />
            {RenderLayout()}
        </>
    );
};

export default Layout;
