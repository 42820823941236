import accessToken from '@/utils/helpers/accessToken.helper';
import request, { ApiRequest, ApiResponse } from '@/utils/helpers/apiResponse.helper';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { UserStation } from './getUserStations.slice';

export interface getUserStationState {
    isLoading: boolean;
    error: Error | null;
    userStation: UserStation | null;
}

export const getUserStationThunk = createAsyncThunk<
    UserStation,
    { id: string; idStation: string },
    { rejectValue: Error }
>('userStations/getUserStation', async (params, thunkApi) => {
    return request({
        url: `/api/users/${params.id}/stations/${params.idStation}`,
        method: 'GET',
        extraHeaders: {
            Authorization: `Bearer ${accessToken.get()}`
        }
    } as ApiRequest)
        .then((res: ApiResponse<UserStation>) => {
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            toast.error('No se encontró la instalación');
            return thunkApi.rejectWithValue(err.data);
        });
});

export const getUserStationSlice = createSlice({
    name: 'userStation',
    initialState: { isLoading: false, error: null, userStation: null } as getUserStationState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getUserStationThunk.fulfilled, (state, { payload }) => {
            (state.isLoading = false), (state.error = null), (state.userStation = payload);
        });
    }
});
