.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    height: 100%;
    width: 100%;
    .label {
        font-weight: bold;
    }
}
.input {
    background-color: var(--color-primary);
    height: 100%;
    padding: 0 5px 0 5px;
    color: #ffffff;
    border: none;
    outline: none;
    border-radius: 5px;
    font-weight: 600;
    min-height: 40px;
}

::-webkit-calendar-picker-indicator {
    padding: 5px;
    cursor: pointer;
    border-radius: 3px;
    filter: invert(1);
}
