.modal {
    position: absolute;
    z-index: 10;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    //overflow-wrap: anywhere;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    &__active {
        display: block;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    &__inActive {
        display: none;
    }
}

/* Modal Content/Box */
.modalContent {
    min-width: 260px;
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    border-radius: 10px;
    width: auto;
}

/* The Close Button */
.close {
    color: #aaa;
    float: right;
    font-size: var(--font-extra-large);
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.title {
    color: var(--color-primary);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--font-large);
    font-weight: bolder;
    text-align: center;
}
