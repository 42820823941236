.headerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.name {
    margin-left: 70px;
    font-size: 40px;
    color: #777777;
    margin-top: 20px;
    margin-bottom: 20px;
}

.street {
    margin-left: 70px;
    color: #777777;
    font-size: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.locationIcon {
    height: 35px;
    margin-right: 5px;
}

.navContainer {
    display: flex;
}

.navItem {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fe6b03;
    font-size: 22px;
    color: white;
    margin-left: 10px;
    padding: 20px 80px;
    height: 15px;
    width: 70px;
}