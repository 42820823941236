import accessToken from '@/utils/helpers/accessToken.helper';
import request, { ApiResponse } from '@/utils/helpers/apiResponse.helper';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export interface PostUserLogoState {
    isLoading: boolean;
    error: Error | null;
    data: StationDocument;
}

type StationDocument = {
    id: string;
    name: string;
    description: string;
    type: string;
    size: number;
    created_at: string;
    updated_at: string;
    url: string;
};
export const postUserLogoThunk = createAsyncThunk<
    StationDocument,
    { document: FormData },
    { rejectValue: Error }
>('users/patchUserLogo', async (params, thunkApi) => {
    return request({
        url: '/api/users/logo',
        method: 'POST',
        data: params.document,
        extraHeaders: {
            Authorization: `Bearer ${accessToken.get()}`
        }
    })
        .then((res: ApiResponse<StationDocument>) => {
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            return thunkApi.rejectWithValue(err.data);
        });
});

export const postUserLogoSlice = createSlice({
    name: 'postUserLogo',
    initialState: {
        isLoading: false,
        error: null,
        data: {
            id: '',
            name: '',
            description: '',
            type: '',
            size: 0,
            created_at: '',
            updated_at: '',
            url: ''
        }
    } as PostUserLogoState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(postUserLogoThunk.fulfilled, (state, { payload }) => {
            (state.isLoading = false), (state.error = null), (state.data = payload);
        });
    }
});
