import { useAppDispatch } from "@/index";
import { StationAlarmsCreateEdit } from "@/models/StationAlarmsCreateEdit";
import { RootState } from "@/store/rootReducer";
import { createStationAlarmThunk, getStationStringsByIdThunk, setSelectedStationId, updateStationAlarmThunk } from "@/store/stations/stations.slice";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";


function CreateEditAlarmController() {
    const dispatch = useAppDispatch()
    const setSelectedStationAction = (selectedStationId?: string) => dispatch(setSelectedStationId(selectedStationId))
    const stationAlarms = useSelector((state: RootState) => state.stations.stationAlarms);
    const stationStrings = useSelector((state: RootState) => state.stations.stationStrings);
    const [error, setError] = useState<string>('')

    const { stationId, alarmId } = useParams()
    const navigate = useNavigate();


    const [alarm, setAlarm] = useState<StationAlarmsCreateEdit>({
        string_id: "",
        min_value: undefined,
        max_value: undefined,
        days: [],
        start_time: "",
        end_time: "",
        enabled: 1
    });

    useEffect(() => {
        if (stationId) {
            setSelectedStationAction(stationId);
            dispatch(getStationStringsByIdThunk({ station_id: stationId }));
        }
    }, [stationId])

    useEffect(() => {
        if (alarmId) {
            let alarm = stationAlarms?.find(a => a.id === alarmId);
            if (alarm) {
                const { string, min_value, max_value, days, time, enabled } = alarm;
                const [startTime, endTime] = time.split(" - ");
                // delete milliseconds from time
                const start_time = startTime.substring(0, startTime.length - 3);
                const end_time = endTime.substring(0, endTime.length - 3);

                const editAlarm: StationAlarmsCreateEdit = {
                    string_id: string,
                    min_value,
                    max_value,
                    days,
                    start_time,
                    end_time,
                    enabled
                }
                setAlarm(editAlarm)
            } else {
                navigate(`/${stationId}/alarms`)
            }
        }
    }, [alarmId, stationAlarms])


    const onSaveEditAlarm = async (alarmId?: string) => {
        setError('')
        if (alarm.string_id === "") {
            setError('Selecciona un String')
        } else if (stationId && alarmId) {
            await dispatch(updateStationAlarmThunk({ station_id: stationId, alarm_id: alarmId, alarm }));
            navigate(`/${stationId}/alarms`)
        } else if (stationId) {
            await dispatch(createStationAlarmThunk({ station_id: stationId, alarm }));
            navigate(`/${stationId}/alarms`)
        }
    }


    return ({ alarm, stationStrings, setAlarm, onSaveEditAlarm, setSelectedStationAction, error });

}

export default CreateEditAlarmController;