import Input from '../Input/Input';
import styles from './LoginComponent.module.scss';

import EmailIcon from '../../assets/icons/EmailIcon.png';
import PasswordIcon from '../../assets/icons/PasswordIcon.png';
import Inicio from '@/assets/images/Inicio.jpg';
import LoginComponentController from './LoginComponent.controller';
import { Link } from 'react-router-dom';

const LoginComponent = () => {
    const { email, setEmail, password, setPassword, isLoading, login, error, handleKeyPress } =
        LoginComponentController();

    return (
        <div
            className={styles.loginContainer}
            style={{
                background: `url(${Inicio})`,
                backgroundSize: 'cover'
            }}
        >
            <p className={styles.title}>Área de usuarios</p>
            <Input
                placeholder="Email"
                iconLeft={EmailIcon}
                value={email}
                onChange={setEmail}
                onKeyPress={handleKeyPress}
            />
            <Input
                placeholder="Contraseña"
                iconLeft={PasswordIcon}
                value={password}
                onChange={setPassword}
                secureTextEntry
                onKeyPress={handleKeyPress}
            />
            {error && <p className={styles.error}>{error}</p>}

            <Link to={'/recover-password'} className={styles.recoverPassword}>
                Recuperar contraseña
            </Link>

            <button
                className={styles.button}
                onClick={() => {
                    login();
                }}
            >
                INICIAR SESIÓN
            </button>
        </div>
    );
};

export default LoginComponent;
