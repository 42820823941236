import { useAppDispatch } from '@/index';
import { loginThunk } from '@/store/auth/login.slice';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function LoginComponentController() {
    const appDispatch = useAppDispatch();

    const dispatch = useAppDispatch();
    const [email, setEmail] = useState<string>('');
    const [error, setError] = useState<string | undefined>(undefined);

    const [password, setPassword] = useState<string>('');
    const [isLoading, setLoading] = useState<boolean>(false);

    const navigate = useNavigate();

    const login = async () => {
        appDispatch(loginThunk({ email, password })).then((res) => {
            navigate('/');
        });
    };

    //     try {
    //         setLoading(true)
    //         const response = await dispatch(loginThunk({ email, password })).unwrap()
    //         if (response.token) {
    //             navigate('/')
    //         }
    //         setLoading(false)

    //     } catch (error) {
    //         setLoading(false)
    //         console.log('ERROR: ', error);
    //         setError('Usuario o contraseña incorrectos')
    //     }
    // }
    const handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            login();
        }
    };
    return {
        email,
        setEmail,
        password,
        setPassword,
        isLoading,
        setLoading,
        login,
        error,
        handleKeyPress
    };
}

export default LoginComponentController;
