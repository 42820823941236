import { useAppDispatch } from '@/index';
import { StationResume } from '@/models/StationResume';
import { RootState } from '@/store/rootReducer';
import { getStationResumeByIdThunk } from '@/store/stations/stations.slice';

import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const all_status: any = {
    thunderstorm: 'Tormenta',
    drizzle: 'Llovizna',
    rain: 'Lluvia',
    snow: 'Nieve',
    mist: 'Niebla',
    clear: 'Despejado',
    cloudy: 'Nublado',
    unknown: 'Desconocido'
};

function StatusIndicatorController() {
    const dispatch = useAppDispatch();
    const location = useLocation();

    const selectedStationId = useSelector((state: RootState) => state.stations.selectedStationId);

    const selStationResume: StationResume | undefined = useSelector(
        (state: RootState) => state.stations.selectedStationResume
    );
    const stationsResume: StationResume | undefined = useSelector(
        (state: RootState) => state.stations.stationsResume
    );

    const parseStatusText = (status: string) => {
        return all_status[status] ?? 'Desconocido';
    };

    const convertToEuropean = (num: number | undefined) => {
        if (!num) return '0';

        console.log("NUM", num);
        const parts = num.toFixed(2).split('.');
        const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        const decimalPart = parts[1];
        return `${integerPart},${decimalPart}`;
    };

    const selectedStationResume: StationResume | undefined =
        selectedStationId === undefined ? stationsResume : selStationResume;

    useEffect(() => {
        if (selectedStationId) {
            dispatch(getStationResumeByIdThunk({ station_id: selectedStationId }));
        }
    }, [selectedStationId]);

    useEffect(() => {
        dispatch(getStationResumeByIdThunk({ station_id: selectedStationId }));
    }, []);

    return { selectedStationResume, parseStatusText, convertToEuropean };
}

export default StatusIndicatorController;
