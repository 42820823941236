
import EMSolar0 from '../../assets/images/emsolar_0.png'
import EMSolar25 from '../../assets/images/emsolar_25.png'
import EMSolar50 from '../../assets/images/emsolar_50.png'
import EMSolar75 from '../../assets/images/emsolar_75.png'
import EMSolar100 from '../../assets/images/emsolar_100.png'

import InvestmentRecuperationController from './InvestmentRecuperation.controller';

import styles from './InvestmentRecuperation.module.scss'


const getImage = (porcentage: number) => {
  if (porcentage > 0 && porcentage <= 25) {
    return EMSolar0
  } else if (porcentage > 25 && porcentage <= 50) {
    return EMSolar25
  } else if (porcentage > 50 && porcentage <= 75) {
    return EMSolar50
  } else if (porcentage > 75 && porcentage < 100) {
    return EMSolar75
  } else {
    return EMSolar100
  }
}

const InvestmentRecuperation = () => {
  const { investment_payback } = InvestmentRecuperationController()

  const generated_income = investment_payback?.generated_income ?? 0

  const cost = investment_payback?.cost ?? 0

  const percentage = cost > 0
    ? generated_income / cost * 100
    : 100

  return (
    <div className={styles.stationDetailsItem}>
      <p className={styles.stationDetailsItemTitle}>Recuperación de inversión</p>
      <p className={styles.investmentRecuperationText}>
        <span style={{ color: '#e9781c' }}>{generated_income}€</span> / {cost}€</p>
      <img className={styles.image} src={getImage(percentage)} />
      <p className={styles.investmentRecuperationText}>{percentage.toFixed(2)}%</p>
    </div>
  );


}

export default InvestmentRecuperation;