.checkedCheckbox {
    width: 20px;
    height: 20px;
    min-width:max-content;
    background-color: #ec8d4b;
    margin: 20px;
    border: 3px solid #e87728;
}


.checkbox {
    width: 20px;
    height: 20px;
    min-width: max-content;
    background-color: white;
    margin: 20px;
    border: 3px solid #e87728;
}