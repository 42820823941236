import styles from './AddNewAccount.module.scss';
import HeaderAdmin from '@/components/HeaderAdmin/HeaderAdmin';
import { useNavigate } from 'react-router-dom';
import { Form, Formik, FormikProps } from 'formik';
import InputSelect from '@/components/InputsFormik/InputSelect/InputSelect';
import InputText from '@/components/InputsFormik/InputText/InputText';
import Button from '@/components/Button/Button';
import { ButtonSizes, ButtonVariants } from '@/components/Button/Button.types';
import { NewAccountSchema } from './AddNewAccount.yup';
import { useAppDispatch } from '@/index';
import { postUsersCredentialsThunk } from '@/store/users/postUsersCredentials';
const AddNewAccount = () => {
    const navigate = useNavigate();
    const appDispatch = useAppDispatch();

    const initialValues = {
        provider: { value: 'huawei', label: 'Huawei' },
        username: '',
        password: ''
    };
    const handleSubmit = (values: any, actions: any) => {
        appDispatch(
            postUsersCredentialsThunk({
                provider: values.provider,
                username: values.username,
                password: values.password
            })
        ).then(() => {
            navigate('/');
        });
    };
    return (
        <>
            <div className={styles.container}>
                <div className={styles.content}>
                    <HeaderAdmin titleBold="Administración /&nbsp;" title="AÑADIR NUEVA CUENTA" />
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        validationSchema={NewAccountSchema}
                        onSubmit={handleSubmit}
                    >
                        {(
                            formik: FormikProps<{
                                provider: { value: string; label: string };
                                username: string;
                                password: string;
                            }>
                        ) => (
                            <>
                                <Form className={styles.formContainer}>
                                    <div className={styles.formContent}>
                                        <InputSelect
                                            label={'Modalidad de tarifa contratada por el cliente'}
                                            name="provider"
                                            options={[{ value: 'huawei', label: 'Huawei' }]}
                                        />
                                        <InputText
                                            label={'Usuario de acceso'}
                                            name="username"
                                            placeholder="Usuario para acceder al inversor"
                                        />
                                        <InputText
                                            label={'Contraseña'}
                                            name="password"
                                            placeholder="Contraseña del usuario"
                                            type="password"
                                        />
                                    </div>

                                    <div className={styles.button}>
                                        <Button
                                            type={'submit'}
                                            size={ButtonSizes.ADAPTABLE}
                                            variant={ButtonVariants.PRIMARY}
                                        >
                                            AÑADIR CUENTA
                                        </Button>
                                    </div>
                                </Form>
                            </>
                        )}
                    </Formik>
                </div>
            </div>
        </>
    );
};

export default AddNewAccount;
