import { RootState } from "@/store/rootReducer";
import { useSelector } from "react-redux";



function InvestmentRecuperationController() {
    const investment_payback = useSelector((state: RootState) => state.stations.stationDetail?.investment_payback);


    return { investment_payback  }

}

export default InvestmentRecuperationController;
