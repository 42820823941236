import TimeRangeSelector from '../TimeRangeSelector/TimeRangeSelector';
import { BarChart, Bar, YAxis, XAxis, ResponsiveContainer, LabelList } from 'recharts';

import styles from './ProducedCapitalChart.module.scss'
import ProducedCapitalChartController from './ProducedCapitalChart.controller';
import Spinner from '../Spinner/Spinner';

const ProducedCapitalChart = () => {

    const { data, timeRangeSelectorValue, onChangeTimeRangeSelectorValue } = ProducedCapitalChartController();

    return (
        <div className={styles.container}>
            <div className={styles.titleRow}>
                <div>
                    <div className={styles.title}>Capital producido (€)</div>
                </div>
                <TimeRangeSelector value={timeRangeSelectorValue} onSelected={onChangeTimeRangeSelectorValue} />

            </div>
            {data === undefined
                ? <Spinner isLoading />
                : <ResponsiveContainer width='100%' height={400}>
                    <BarChart
                        data={data}
                        margin={{ top: 40 }}
                    >
                        <XAxis dataKey="key" />
                        <YAxis />
                        <Bar dataKey="value" fill="#e66c23" >
                            <LabelList dataKey="value" position="top" />
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>}
        </div>
    )
}

export default ProducedCapitalChart;