import PlantNavigationHeader from '@/components/StationNavigationHeader/StationNavigationHeader';

import styles from './StationDocumentation.module.scss';

import ContractIcon from '../../assets/icons/ContractIcon.png';
import DocumentationIcon from '../../assets/icons/DocumentationIcon.png';
import InvoiceIcon from '../../assets/icons/InvoiceIcon.png';
import ProjectInfoIcon from '../../assets/icons/ProjectInfoIcon.png';
import UploadLogoIcon from '../../assets/icons/UploadLogoIcon.png';
import AddIcon from '../../assets/icons/AddIcon.png';
import PDFIcon from '../../assets/icons/PDFIcon.png';
import PNGIcon from '../../assets/icons/PNGIcon.png';
import UploadIcon from '../../assets/icons/UploadIcon.png';
import StationDocumentationController from './StationDocumentation.controller';
import Spinner from '@/components/Spinner/Spinner';

const StationDocumentation = () => {
    const {
        selectedTab,
        setSelectedTab,
        showUploadFile,
        setShowUploadFile,
        handleFileInput,
        logo,
        info,
        contract,
        certificates,
        receipt,
        isUploadingFile,
        stationData,
        isClient,
        handleDeleteFile,
        isAdmin
    } = StationDocumentationController();

    const renderUnselectedTab = () => (
        <div>
            <PlantNavigationHeader showBack subsectionText="DOCUMENTACIÓN" isMain={true} />
            <div className={styles.mainContainer}>
                {stationData?.user_id && (
                    <div
                        className={styles.documentationElement}
                        onClick={() => setSelectedTab('logo')}
                    >
                        <img className={styles.icon} src={UploadLogoIcon} />
                        <p className={styles.documentationText}>Sube tu logo</p>
                    </div>
                )}

                <div className={styles.documentationElement} onClick={() => setSelectedTab('info')}>
                    <img className={styles.icon} src={ProjectInfoIcon} />
                    <p className={styles.documentationText}>Tu información de proyecto</p>
                </div>
                <div
                    className={styles.documentationElement}
                    onClick={() => setSelectedTab('contract')}
                >
                    <img className={styles.icon} src={ContractIcon} />
                    <p className={styles.documentationText}>Tu contrato</p>
                </div>
                <div
                    className={styles.documentationElement}
                    onClick={() => setSelectedTab('certificates')}
                >
                    <img className={styles.icon} src={DocumentationIcon} />
                    <p className={styles.documentationText}>
                        Documentación y certificados de la instalación
                    </p>
                </div>
                <div
                    className={styles.documentationElement}
                    onClick={() => setSelectedTab('receipt')}
                >
                    <img className={styles.icon} src={InvoiceIcon} />
                    <p className={styles.documentationText}>Tu justificante de servicios</p>
                </div>
            </div>
        </div>
    );

    const downloadFile = (url: string) => {
        window.open(url, '_blank');
    };

    const renderSelectedTab = () => {
        switch (selectedTab) {
            case 'logo':
                return (
                    <div>
                        <PlantNavigationHeader
                            showBack
                            subsectionText="DOCUMENTACIÓN / LOGO DE EMPRESA"
                            onBack={() => setSelectedTab(undefined)}
                        />
                        <div className={styles.mainContainer}>
                            {logo && (
                                <div>
                                    {isAdmin && (
                                        <div
                                            className={styles.fileTypeContainer__deleteOption}
                                            onClick={() => {
                                                handleDeleteFile(logo, 'logo');
                                            }}
                                        >
                                            X
                                        </div>
                                    )}

                                    <div
                                        className={styles.fileTypeContainer}
                                        onClick={() => downloadFile(logo)}
                                    >
                                        <img className={styles.fileTypeIcon} src={PNGIcon} />
                                        <div className={styles.fileTypeText}>Logo</div>
                                    </div>
                                </div>
                            )}
                            <img
                                onClick={() => {
                                    setShowUploadFile(true);
                                }}
                                className={styles.addIcon}
                                src={AddIcon}
                            />
                        </div>
                    </div>
                );
            case 'info':
                return (
                    <div>
                        <PlantNavigationHeader
                            showBack
                            subsectionText="DOCUMENTACIÓN / PROYECTO"
                            onBack={() => setSelectedTab(undefined)}
                        />
                        <div className={styles.mainContainer}>
                            {info?.map((file: any) => (
                                <div>
                                    {isAdmin && (
                                        <div
                                            className={styles.fileTypeContainer__deleteOption}
                                            onClick={() => {
                                                handleDeleteFile(file.url, 'info');
                                            }}
                                        >
                                            X
                                        </div>
                                    )}

                                    <div
                                        className={styles.fileTypeContainer}
                                        onClick={() => downloadFile(file.url)}
                                    >
                                        <img className={styles.fileTypeIcon} src={PDFIcon} />
                                        <div className={styles.fileTypeText}>{file.name}</div>
                                    </div>
                                </div>
                            ))}
                            {!isClient && (
                                <img
                                    onClick={() => {
                                        setShowUploadFile(true);
                                    }}
                                    className={styles.addIcon}
                                    src={AddIcon}
                                />
                            )}
                        </div>
                    </div>
                );
            case 'contract':
                return (
                    <div>
                        <PlantNavigationHeader
                            showBack
                            subsectionText="DOCUMENTACIÓN / CONTRATO"
                            onBack={() => setSelectedTab(undefined)}
                        />
                        <div className={styles.mainContainer}>
                            {contract && (
                                <div>
                                    {isAdmin && (
                                        <div
                                            className={styles.fileTypeContainer__deleteOption}
                                            onClick={() => {
                                                handleDeleteFile(contract, 'contract');
                                            }}
                                        >
                                            X
                                        </div>
                                    )}

                                    <div
                                        className={styles.fileTypeContainer}
                                        onClick={() => downloadFile(contract)}
                                    >
                                        <img className={styles.fileTypeIcon} src={PDFIcon} />
                                        <div className={styles.fileTypeText}>Contrato</div>
                                    </div>
                                </div>
                            )}
                            {!isClient && (
                                <img
                                    onClick={() => {
                                        setShowUploadFile(true);
                                    }}
                                    className={styles.addIcon}
                                    src={AddIcon}
                                />
                            )}
                        </div>
                    </div>
                );
            case 'certificates':
                return (
                    <div>
                        <PlantNavigationHeader
                            showBack
                            subsectionText="DOCUMENTACIÓN / CERTIFICADOS"
                            onBack={() => setSelectedTab(undefined)}
                        />
                        <div className={styles.mainContainer}>
                            {certificates?.map((file: any) => (
                                <div>
                                    {isAdmin && (
                                        <div
                                            className={styles.fileTypeContainer__deleteOption}
                                            onClick={() => {
                                                handleDeleteFile(file.url, 'certificates');
                                            }}
                                        >
                                            X
                                        </div>
                                    )}

                                    <div
                                        className={styles.fileTypeContainer}
                                        onClick={() => downloadFile(file.url)}
                                    >
                                        <img className={styles.fileTypeIcon} src={PDFIcon} />
                                        <div className={styles.fileTypeText}>{file.name}</div>
                                    </div>
                                </div>
                            ))}
                            {!isClient && (
                                <img
                                    onClick={() => {
                                        setShowUploadFile(true);
                                    }}
                                    className={styles.addIcon}
                                    src={AddIcon}
                                />
                            )}
                        </div>
                    </div>
                );
            case 'receipt':
                return (
                    <div>
                        <PlantNavigationHeader
                            showBack
                            subsectionText="DOCUMENTACIÓN / JUSTIFICANTES"
                            onBack={() => setSelectedTab(undefined)}
                        />
                        <div className={styles.mainContainer}>
                            {receipt && (
                                <div>
                                    {isAdmin && (
                                        <div
                                            className={styles.fileTypeContainer__deleteOption}
                                            onClick={() => {
                                                handleDeleteFile(receipt, 'receipt');
                                            }}
                                        >
                                            X
                                        </div>
                                    )}

                                    <div
                                        className={styles.fileTypeContainer}
                                        onClick={() => downloadFile(receipt)}
                                    >
                                        <img className={styles.fileTypeIcon} src={PDFIcon} />
                                        <div className={styles.fileTypeText}>Contrato</div>
                                    </div>
                                </div>
                            )}
                            {!isClient && (
                                <img
                                    onClick={() => {
                                        setShowUploadFile(true);
                                    }}
                                    className={styles.addIcon}
                                    src={AddIcon}
                                />
                            )}
                        </div>
                    </div>
                );
        }
    };

    const renderUploadFile = () => (
        <div>
            <PlantNavigationHeader
                showBack
                subsectionText="DOCUMENTACIÓN / SUBIR ARCHIVO"
                onBack={() => setShowUploadFile(false)}
            />
            <div className={styles.uploadContainer}>
                <img className={styles.uploadIcon} src={UploadIcon} />
                {<Spinner isLoading={isUploadingFile} />}
                <h3>Selecciona archivos para subirlos</h3>
                <label htmlFor="file-upload">
                    <div className={styles.uploadButton}>Seleccionar archivos</div>
                </label>
                <input
                    style={{ visibility: 'hidden' }}
                    type="file"
                    id="file-upload"
                    onChange={handleFileInput}
                />
            </div>
        </div>
    );

    if (showUploadFile) {
        return renderUploadFile();
    } else {
        return selectedTab ? renderSelectedTab() : renderUnselectedTab();
    }
};

export default StationDocumentation;
