import Button from '@/components/Button/Button';
import { ButtonSizes, ButtonVariants } from '@/components/Button/Button.types';
import HeaderAdmin from '@/components/HeaderAdmin/HeaderAdmin';
import InputText from '@/components/InputsFormik/InputText/InputText';
import { MyModal } from '@/components/MyModal/MyModal';
import { useAppDispatch } from '@/index';
import { getUserDataThunk } from '@/store/auth/getUserData';
import { RootState } from '@/store/rootReducer';
import { getUserByIdThunk } from '@/store/users/getUserById.slice';
import { patchUserByIdThunk } from '@/store/users/patchUserById.slice';
import accessToken from '@/utils/helpers/accessToken.helper';
import request from '@/utils/helpers/apiResponse.helper';
import { Form, Formik, FormikProps } from 'formik';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styles from './EditUser.module.scss';
import { EditUserSchema } from './EditUser.yup';

interface UserForm {
    cif: string;
    username: string;
    email: string;
    maintenance_type?: { value: string; label: string };
    password?: string;
}
const EditUser = () => {
    const params = useParams();
    const appDispatch = useAppDispatch();
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const myUserData = useSelector((state: RootState) => state.auth.getUserData.user);
    const UserData = useSelector((state: RootState) => state.users.getUserById.user);

    const onDelete = () => {
        setOpenModal(false);
        if (
            myUserData?.role === 'admin' ||
            (myUserData?.role === 'technician' && UserData?.role === 'client')
        ) {
            if (params.id) {
                request({
                    url: `api/users/${params.id}`,
                    method: 'DELETE',
                    extraHeaders: {
                        Authorization: `Bearer ${accessToken.get()}`
                    }
                }).then((e) => {
                    //TODO modal delete message
                    navigate('/user-management');
                });
            }
        } else {
            toast.warning('Permiso denegado');
        }
    };
    const handleSubmit = (values: UserForm, actions: any) => {
        if (params.id)
            appDispatch(
                patchUserByIdThunk({
                    id: params.id,
                    cif: values.cif,
                    email: values.email,
                    username: values.username
                })
            ).then(() => {
                navigate('/user-management');
            });
    };
    useEffect(() => {
        if (params.id) appDispatch(getUserByIdThunk(params.id));
    }, []);

    useEffect(() => {
        appDispatch(getUserDataThunk());
    }, []);

    return (
        <>
            <div className={styles.container}>
                <div className={styles.content}>
                    <HeaderAdmin
                        titleBold="Administración"
                        //@ts-ignore
                        title={`/ GESTIÓN DE USUARIOS/  ${params.id}`}
                    />
                    <Formik
                        enableReinitialize
                        initialValues={{
                            username: UserData?.username ? UserData.username : '',
                            cif: UserData?.cif ? UserData.cif : '',
                            email: UserData?.email ? UserData.email : ''
                        }}
                        validationSchema={EditUserSchema}
                        onSubmit={handleSubmit}
                    >
                        {(formik: FormikProps<UserForm>) => (
                            <>
                                <Form className={styles.formContainer}>
                                    <div className={styles.formContent}>
                                        <InputText
                                            label={'Nombre completo'}
                                            name="username"
                                            placeholder="Apellido Apellido, Nombre "
                                            handleDisabled={true}
                                        />
                                        <InputText
                                            label={'Correo'}
                                            name="email"
                                            placeholder="Dirección de correo electrónico"
                                            handleDisabled={true}
                                        />
                                        <InputText
                                            label={'Documentación CIF/NIF'}
                                            name="cif"
                                            placeholder="Número de documento"
                                            handleDisabled={true}
                                        />

                                        {(myUserData?.role === 'admin' ||
                                            (myUserData?.role === 'technician' &&
                                                UserData?.role === 'client')) && (
                                            <div className={styles.deleteOption}>
                                                <label className={styles.deleteOption__label}>
                                                    Eliminar usuario
                                                </label>
                                                <div className={styles.deleteOption__button}>
                                                    <Button
                                                        type={'button'}
                                                        size={ButtonSizes.ADAPTABLE}
                                                        variant={ButtonVariants.PRIMARY_OUTLINE}
                                                        onClick={() => {
                                                            setOpenModal(true);
                                                        }}
                                                    >
                                                        Eliminar
                                                    </Button>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className={styles.buttons}>
                                        <Button
                                            type={'submit'}
                                            size={ButtonSizes.ADAPTABLE}
                                            variant={ButtonVariants.PRIMARY}
                                        >
                                            GUARDAR CAMBIOS
                                        </Button>
                                        <Button
                                            type={'button'}
                                            size={ButtonSizes.ADAPTABLE}
                                            variant={ButtonVariants.PRIMARY_OUTLINE}
                                            onClick={() => {
                                                if (params.id)
                                                    navigate(`/user-stations/${params.id}`);
                                            }}
                                        >
                                            GESTIONAR INSTALACIONES
                                        </Button>
                                    </div>
                                    <div className={styles.button}></div>
                                </Form>
                            </>
                        )}
                    </Formik>
                </div>
            </div>
            <MyModal
                handleClose={() => setOpenModal(false)}
                isOpen={openModal}
                title="¿DESEA ELIMINAR ÉSTE USUARIO?"
            >
                <div className={styles.contentModal}>
                    <div className={styles.buttons}>
                        <Button
                            size={ButtonSizes.ADAPTABLE}
                            variant={ButtonVariants.DANGER_OUTLINE}
                            onClick={() => {
                                setOpenModal(false);
                            }}
                        >
                            CANCELAR
                        </Button>
                        <Button
                            size={ButtonSizes.ADAPTABLE}
                            variant={ButtonVariants.DANGER}
                            onClick={onDelete}
                        >
                            ELIMINAR
                        </Button>
                    </div>
                </div>
            </MyModal>
        </>
    );
};

export default EditUser;
