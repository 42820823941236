import HomeAdminController from './HomeAdmin.controller';
import { ColumnDef } from '@tanstack/react-table';
import Table from '@/components/HomeTable/HomeTable';
import { useNavigate } from 'react-router-dom';
import OnGreenIcon from '@/assets/icons/OnGreenIcon.png';
import OffRedIcon from '@/assets/icons/OffRedIcon.png';
import PowerGridGreenIcon from '@/assets/icons/PowerGridGreenIcon.png';
import PowerGridYellowIcon from '@/assets/icons/PowerGridYellowIcon.png';
import styles from './HomeAdmin.module.scss';
import { StationList } from '@/models/StationList';
import Button from '@/components/Button/Button';
import { ButtonSizes, ButtonVariants } from '@/components/Button/Button.types';
import SearchBar from '@/components/SearchBar/SearchBar';
import SettingIcon from '@/assets/icons/gear-fill.svg';
import DocIcon from '@/assets/icons/doc.svg';
import Pagination from '@/components/Pagination/Pagination';

const HomeAdmin = () => {
    const {
        setSelectedStationAction,
        stationList,
        selectedStationId,
        getStationsStatus,
        searchText,
        searchByValue,
        setSearchText,
        setSearchByValue,
        handleChangePage,
        max_pages,
        current_page
    } = HomeAdminController();
    const navigate = useNavigate();

    const columns: ColumnDef<StationList>[] = [
        {
            id: 'selector',
            header: 'Cliente',
            cell: (info) => {
                return <div>{info.row.original.client}</div>;
            }
        },
        {
            header: 'Estado',
            cell: (info) => (
                <div className={styles.tableCell}>
                    <img
                        className={styles.icon}
                        src={info.row.original.status === 'active' ? OnGreenIcon : OffRedIcon}
                    />
                    <img
                        className={styles.icon}
                        src={
                            info.row.original.status === 'active'
                                ? PowerGridGreenIcon
                                : PowerGridYellowIcon
                        }
                    />
                </div>
            )
        },

        {
            accessorKey: 'name',
            cell: (info) => info.getValue(),
            header: 'Nombre'
        },
        {
            accessorKey: 'total_capacity',
            cell: (info: any) => info.getValue()?.toLocaleString() + ' kWp',
            header: 'Capacidad Total'
        },
        {
            accessorKey: 'power',
            cell: (info: any) => info.getValue()?.toLocaleString() + ' kW',
            header: 'Potencia Actual'
        },
        {
            accessorKey: 'today_accumulated_energy',
            cell: (info: any) => info.getValue()?.toLocaleString() + ' kWh',
            header: 'Rendimiento Hoy'
        },
        {
            accessorKey: 'total_accumulated_energy',
            cell: (info: any) => info.getValue()?.toLocaleString('de-DE') + ' kWp',
            header: 'Rendimiento Total'
        },
        {
            header: 'Administrar',
            cell: (info) => (
                <div>
                    <div className={styles.options}>
                        <SettingIcon
                            className={styles.optionIcon}
                            onClick={() => {
                                navigate(`/edit-station/${info.row.original.id}`);
                            }}
                        />
                        <DocIcon
                            className={styles.optionIcon}
                            onClick={() => {
                                navigate(`/documentation/${info.row.original.id}`);
                            }}
                        />
                    </div>
                </div>
            )
        }
    ];
    return (
        <>
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.headerContent}>
                        <div className={styles.buttons}>
                            <Button
                                onClick={() => {
                                    navigate('/user-management');
                                }}
                                size={ButtonSizes.ADAPTABLE}
                                variant={ButtonVariants.PRIMARY}
                            >
                                GESTIONAR USUARIOS
                            </Button>
                            <Button
                                onClick={() => {
                                    navigate('/add-new-account');
                                }}
                                size={ButtonSizes.ADAPTABLE}
                                variant={ButtonVariants.PRIMARY_OUTLINE}
                            >
                                AÑADIR NUEVA CUENTA
                            </Button>
                        </div>
                        <div className={styles.searchBar}>
                            <SearchBar
                                searchByValue={searchByValue}
                                searchText={searchText}
                                setSearchByValue={setSearchByValue}
                                setSearchText={setSearchText}
                                onButtonClick={() => {
                                    getStationsStatus();
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.table}>
                    <Table
                        selectedStationId={selectedStationId}
                        data={stationList ?? []}
                        columns={columns}
                    />
                </div>

                <Pagination
                    handleChangePage={handleChangePage}
                    maxPages={max_pages}
                    currentPage={current_page}
                />
            </div>
        </>
    );
};

export default HomeAdmin;
