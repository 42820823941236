.container {
    background-color: rgb(245, 243, 243);
    min-height: var(--body-height);
    display: flex;
    flex-direction: column;

    .content {
        padding: 30px 30px 15px 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        .table {
            width: 100%;
            overflow: hidden;
            overflow-y: scroll;
            height: 500px;
        }
    }
    .button {
        align-self: center;
        width: 400px;
        height: 40px;
    }
}

.containerModal {
    min-width: 1000px;
    min-height: 350px;

    .formContainer {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 50px;
        .formContent {
            width: 800px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto 1fr auto;
            gap: 30px;
            align-items: start;
        }
        .button {
            width: 200px;
            height: 50px;
            margin-bottom: 20px;
        }
        .rates {
            grid-column: 1 / 3;
            &__title {
                font-weight: bolder;
            }
            &__grid {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 30px;
            }
        }
    }
}

.tableCell {
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon {
    height: 40px;
    width: 40px;
    object-fit: contain;
}
