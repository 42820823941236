import styles from './UsersTable.module.scss';
import SettingIcon from '@/assets/icons/gear-fill.svg';
import { useNavigate } from 'react-router-dom';
import { UserRoleTypes } from '@/layout/Layout';
import { Role } from '@/store/users/getUsers.slice';
import { useEffect } from 'react';
import { useAppDispatch } from '@/index';
import { getUserDataThunk } from '@/store/auth/getUserData';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/rootReducer';
import Button from '../Button/Button';
import { ButtonSizes, ButtonVariants } from '../Button/Button.types';
import { adminUserLoginThunk } from '@/store/auth/login.slice';

interface UsersTableProps {
    headers: string[];
    rows: any[];
}
const UsersTable = ({ headers, rows }: UsersTableProps) => {
    const navigate = useNavigate();
    const appDispatch = useAppDispatch();
    const UserData = useSelector((state: RootState) => state.auth.getUserData.user);

    const login = async (user_id) => {
        appDispatch(adminUserLoginThunk({ user_id })).then((res) => {
            if (res.error) {
                navigate("/");
                console.log("ERROR LOGGING IN AS USER ID: ", user_id);
                navigate(0);
            } else {
                navigate("/");
                console.log("LOGGING IN AS USER ID: ", user_id);
                navigate(0);
            }
        }).catch((err) => {
            console.log(err);
        });
    };

    useEffect(() => {
        appDispatch(getUserDataThunk());
    }, []);
    return (
        <table className={styles.table}>
            <thead>
                <tr>
                    {headers.map((header, index) => {
                        if (UserData?.role) {
                            return (
                                <th key={index} className={styles.tableHeader}>
                                    {header}
                                </th>
                            );
                        }
                    })}
                </tr>
            </thead>
            <tbody>
                {rows.map((row, key) => {
                    return (
                        <tr
                            style={{
                                backgroundColor: 'white'
                            }}
                            key={key}
                        >
                            <td className={styles.tableCell}>{row.username}</td>
                            <td className={styles.tableCell}>{row.email}</td>
                            {/* @ts-ignore */}
                            <td className={styles.tableCell}>{Role[row.role]}</td>
                            {UserData?.role && (
                                <td className={styles.tableCell}>
                                    <SettingIcon
                                        className={styles.icon}
                                        onClick={() => {
                                            if (UserData.role == UserRoleTypes.ADMIN) {
                                                navigate(`/edit-user/${row.id}`);
                                            } else if (UserData.role == UserRoleTypes.TECHNICIAN) {
                                                if (row.role === UserRoleTypes.CLIENT) {
                                                    navigate(`/edit-user/${row.id}`);
                                                } else {
                                                    navigate(`/user-stations/${row.id}`);
                                                }
                                            }
                                        }}
                                    />
                                </td>
                            )}
                            {UserData.role == UserRoleTypes.ADMIN && (
                                <td className={styles.tableCell}>
                                    <div className={styles.button}>
                                        {
                                            <Button
                                                onClick={async () => {
                                                    login(row.id);
                                                }}
                                                size={ButtonSizes.ADAPTABLE}
                                                variant={ButtonVariants.PRIMARY}
                                            >
                                                ACCEDER
                                            </Button>
                                        }
                                    </div>
                                </td>
                               )}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default UsersTable;
