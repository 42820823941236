import PlantNavigationHeader from '@/components/StationNavigationHeader/StationNavigationHeader';
import styles from './StationDetail.module.scss';

import EconomicPerformanceBarChart from '@/components/EconomicPerformanceBarChart/EconomicPerformanceBarChart';
import ProductedConsumedEnergyChart from '@/components/ProductedConsumedEnergyChart/ProductedConsumedEnergyChart';
import StationDetailController from './StationDetail.controller';
import WastedEnergyChart from '@/components/WastedEnergyChart/WastedEnergyChart';
import InvestmentRecuperation from '@/components/InvestmentRecuperation/InvestmentRecuperation';
import EnergyMap from '@/components/EnergyMap/EnergyMap';

const StationDetail = () => {
    const { size } = StationDetailController();

    return (
        <div key={size[0]}>
            <PlantNavigationHeader />
            <div className={styles.stationDetailsContainer}>
                <EconomicPerformanceBarChart key={size[0]} />
                <ProductedConsumedEnergyChart key={size[0]} />
            </div>
            <div className={styles.stationDetailsContainer}>
                <WastedEnergyChart key={size[0]} />
                <InvestmentRecuperation key={size[0]} />
            </div>
            <div className={styles.stationDetailsContainer}>
                <EnergyMap />
                <div
                    style={{
                        flex: 1,
                        margin: 38
                    }}
                />
            </div>
        </div>
    );
};

export default StationDetail;
