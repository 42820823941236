import Home from '@/pages/Home/Home';
import PrivateRoute from '../PrivateRoute';

import PlantAlarms from '@/pages/StationAlarms/StationAlarms';
import PlantData from '@/pages/StationData/StationData';
import PlantDescription from '@/pages/StationDevice/StationDevice';
import PlantDetail from '@/pages/StationDetail/StationDetail';

import CreateEditAlarm from '@/pages/CreateEditAlarm/CreateEditAlarm';
import StationInverters from '@/pages/StationDevices/StationDevices';
import StationDocumentation from '@/pages/StationDocumentation/StationDocumentation';

const ClientRoutes = [
    {
        path: '/*',
        element: (
            <PrivateRoute>
                <Home />
            </PrivateRoute>
        )
    },
    {
        path: '/:stationId',
        element: (
            <PrivateRoute>
                <PlantDetail />
            </PrivateRoute>
        )
    },
    {
        path: '/:stationId/data',
        element: (
            <PrivateRoute>
                <PlantData />
            </PrivateRoute>
        )
    },
    {
        path: '/:stationId/documentation',
        element: (
            <PrivateRoute>
                {/**@ts-ignore */}
                <StationDocumentation />
            </PrivateRoute>
        )
    },
    {
        path: '/:stationId/devices',
        element: (
            <PrivateRoute>
                <StationInverters />
            </PrivateRoute>
        )
    },
    {
        path: '/:stationId/device/:deviceId',
        element: (
            <PrivateRoute>
                <PlantDescription />
            </PrivateRoute>
        )
    },
    {
        path: '/:stationId/alarms',
        element: (
            <PrivateRoute>
                <PlantAlarms />
            </PrivateRoute>
        )
    },
    {
        path: '/:stationId/alarms/create',
        element: (
            <PrivateRoute>
                <CreateEditAlarm />
            </PrivateRoute>
        )
    },
    {
        path: '/:stationId/alarms/edit/:alarmId',
        element: (
            <PrivateRoute>
                <CreateEditAlarm />
            </PrivateRoute>
        )
    }
];

export default ClientRoutes;
