import Button from '@/components/Button/Button';
import { ButtonSizes, ButtonVariants } from '@/components/Button/Button.types';
import HeaderAdmin from '@/components/HeaderAdmin/HeaderAdmin';
import InputSelect from '@/components/InputsFormik/InputSelect/InputSelect';
import InputTextV2 from '@/components/InputsFormik/InputTextV2/InputTextV2';
import { MyModal } from '@/components/MyModal/MyModal';
import { Form, Formik, FormikProps } from 'formik';
import { useEffect, useState } from 'react';
//import { useNavigate } from 'react-router-dom';
import {
    headersManageStationsByUser
    // initialValuesManageStationsByUser
} from './ManageUserStations.data';
import styles from './ManageUserStations.module.scss';
// import { StationByUserSchema } from './ManageUserStations.yup';
import UserStationsTable from '@/components/UserStationsTable/UserStationsTable';
import { useAppDispatch } from '@/index';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/rootReducer';
import { PricingType } from '@/store/userStations/getUserStations.slice';
import { patchNoUserStationThunk } from '@/store/userStations/patchNoUserStation.slice';
import InputDate from '@/components/InputsFormik/InputDate/InputDate';
import {
    getUsersStationsSlice,
    getUsersStationsThunk
} from '@/store/userStations/getUsersStations.slice';
import { postUserStationsThunk } from '@/store/userStations/postUserStations.slice';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Pagination from '@/components/Pagination/Pagination';
import { generateFilter } from '@/utils/helpers/filter.helper';

interface StationFormik {
    id: string;
    client: string;
    pricing_type: { value: string; label: string };
    tariff: { value: string; label: string };
    start_time: string;
    tiers: {
        P1?: string | null;
        P2?: string | null;
        P3?: string | null;
        P4?: string | null;
        P5?: string | null;
        P6?: string | null;
    };
}
const ManageUserStations = () => {
    //const navigate = useNavigate();
    const appDispatch = useAppDispatch();
    const { id } = useParams();
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [selectedItems, setSelectedItems] = useState<any>({});
    const [selectedItemModal, setSelectedItemModal] = useState<string>('');

    const handleSelectedItems = (id: string) => {
        if (selectedItems[id]) {
            setSelectedItems({ ...selectedItems, [id]: false });
        } else {
            setSelectedItems({ ...selectedItems, [id]: true });
        }
    };
    const headers = headersManageStationsByUser;

    const { usersStations, max_pages, current_page } = useSelector(
        (state: RootState) => state.userStations.getUsersStations
    );

    const handleSubmit = (values: any, actions: any) => {
        const dataValues = {
            pricing_type: values.pricing_type.value,
            tariff: values.tariff.value,
            tiers: { ...values.tiers },
            start_time: values.start_time
        };
        appDispatch(patchNoUserStationThunk({ idStation: selectedItemModal, data: dataValues }));
        handleChangePage(current_page);
        setOpenModal(false);
    };
    const generateStructure = (itemID: string): StationFormik => {
        const element = usersStations?.filter((e, i) => {
            return e.id === itemID;
        });
        //@ts-ignore
        const aux = {
            id: '',
            client: '',
            pricing_type: 'pool',
            tariff: '2.0TD',
            start_time: '',
            tiers: {
                P1: '0',
                P2: '0',
                P3: '0'
            }
        };
        const item = element && element.length > 0 ? element[0] : aux;

        const b: StationFormik = {
            id: item.id,
            client: item.client, //@ts-ignore
            pricing_type: { value: item.pricing_type, label: PricingType[item.pricing_type] },
            tariff: { value: item.tariff, label: item.tariff },
            start_time: item.start_time ? item.start_time : '',
            tiers: {
                ...item.tiers
            }
        };

        return b;
    };

    const handleChangePage = (page: number) => {
        appDispatch(getUsersStationsThunk(generateFilter({ limit: 8, page })));
    };

    useEffect(() => {
        handleChangePage(current_page);
    }, []);
    return (
        <>
            <div className={styles.container}>
                <div className={styles.content}>
                    <HeaderAdmin
                        titleBold="Administración /&nbsp;"
                        title="Instalaciones/&nbsp;Agregar "
                    />
                    <div className={styles.table}>
                        <UserStationsTable
                            headers={headers}
                            rows={usersStations ? usersStations : []}
                            onEditClick={(id) => {
                                setOpenModal(true);
                                setSelectedItemModal(id);
                            }}
                            onCheck={(id) => {
                                handleSelectedItems(id);
                            }}
                            selectedItems={selectedItems}
                            isCheckable={true}
                            onDeleteClick={() => {}}
                        />
                    </div>
                    <Pagination
                        handleChangePage={handleChangePage}
                        maxPages={max_pages}
                        currentPage={current_page}
                    />
                </div>
                <div className={styles.button}>
                    <Button
                        size={ButtonSizes.ADAPTABLE}
                        variant={ButtonVariants.PRIMARY}
                        onClick={() => {
                            const selected = Object.keys(selectedItems).filter(
                                (e) => e !== undefined && selectedItems[e]
                            );
                            if (selected.length > 0) {
                                appDispatch(
                                    postUserStationsThunk({
                                        id: id ? id : '',
                                        data: { stations: selected }
                                    })
                                ).then(() => {
                                    appDispatch(getUsersStationsSlice.actions.remove());
                                    navigate(`/user-station/${id}`);
                                });
                            } else {
                                handleChangePage(current_page);
                                toast.warning('Selecciona algúna instalación ');
                            }
                        }}
                    >
                        AGREGAR INSTALACIONES SELECCIONADAS
                    </Button>
                </div>
            </div>
            <MyModal
                handleClose={() => {
                    setOpenModal(false);
                }}
                title={`EDITAR INSTALACIÓN ${selectedItemModal}`}
                isOpen={openModal}
            >
                <div className={styles.containerModal}>
                    <Formik
                        enableReinitialize
                        initialValues={generateStructure(selectedItemModal)}
                        // validationSchema={StationByUserSchema}
                        onSubmit={handleSubmit}
                    >
                        {(formik: FormikProps<StationFormik>) => (
                            <>
                                <Form className={styles.formContainer}>
                                    <div className={styles.formContent}>
                                        <InputSelect
                                            label={'Modalidad de tarifa contratada por el cliente'}
                                            name="pricing_type"
                                            options={[
                                                { value: 'fixed', label: 'Importes fijo ' },
                                                { value: 'pool', label: 'Cliente a pool' }
                                            ]}
                                        />
                                        <InputSelect
                                            label={'Tarifa contratada por el cliente'}
                                            name="tariff"
                                            options={[
                                                { value: '2.0TD', label: '2.0TD' },
                                                { value: '3.0TD', label: '3.0TD' },
                                                { value: '6.1TD', label: '6.1TD' }
                                            ]}
                                        />
                                        <InputDate name="start_time" label="Fecha de inicio:" />
                                        <div className={styles.rates}>
                                            <div className={styles.rates__title}>
                                                Introducir valores de tarifas en € por (kw/h)
                                            </div>
                                            <div className={styles.rates__grid}>
                                                <InputTextV2
                                                    label={'P1:'}
                                                    label2={'€'}
                                                    name="tiers.P1"
                                                    placeholder=""
                                                    type="number"
                                                />
                                                <InputTextV2
                                                    label={'P2:'}
                                                    label2={'€'}
                                                    name="tiers.P2"
                                                    placeholder=""
                                                    type="number"
                                                />
                                                <InputTextV2
                                                    label={'P3:'}
                                                    label2={'€'}
                                                    name="tiers.P3"
                                                    placeholder=""
                                                    type="number"
                                                />
                                                {(formik.values.tariff.value === '3.0TD' ||
                                                    formik.values.tariff.value === '6.1TD') && (
                                                    <>
                                                        <InputTextV2
                                                            label={'P4:'}
                                                            label2={'€'}
                                                            name="tiers.P4"
                                                            placeholder=""
                                                            type="number"
                                                        />
                                                        <InputTextV2
                                                            label={'P5:'}
                                                            label2={'€'}
                                                            name="tiers.P5"
                                                            placeholder=""
                                                            type="number"
                                                        />
                                                        <InputTextV2
                                                            label={'P6:'}
                                                            label2={'€'}
                                                            name="tiers.P6"
                                                            placeholder=""
                                                            type="number"
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.button}>
                                        <Button
                                            type={'submit'}
                                            size={ButtonSizes.ADAPTABLE}
                                            variant={ButtonVariants.PRIMARY}
                                        >
                                            Guardar Cambios
                                        </Button>
                                    </div>
                                </Form>
                            </>
                        )}
                    </Formik>
                </div>
            </MyModal>
        </>
    );
};

export default ManageUserStations;
