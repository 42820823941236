import { combineReducers } from 'redux';
import { getUserByIdSlice } from './getUserById.slice';
import { getUserLogoSlice } from './getUserLogo.slice';
import { getUsersSlice } from './getUsers.slice';
import { postUserSlice } from './postUser.slice';
import { postUserLogoSlice } from './postUserLogo.slice';

export const UsersReducer = combineReducers({
    getUsers: getUsersSlice.reducer,
    getUserById: getUserByIdSlice.reducer,
    postUser: postUserSlice.reducer,
    getUserLogo: getUserLogoSlice.reducer,
    postUserLogo: postUserLogoSlice.reducer
});

export const UsersActions = {
    ...getUsersSlice.actions,
    ...getUserByIdSlice.actions,
    ...postUserSlice.actions,
    ...getUserLogoSlice.actions,
    ...postUserLogoSlice.actions
};
