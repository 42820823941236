import Button from '@/components/Button/Button';
import { ButtonSizes, ButtonVariants } from '@/components/Button/Button.types';
import HeaderAdmin from '@/components/HeaderAdmin/HeaderAdmin';
import { MyModal } from '@/components/MyModal/MyModal';
import Pagination from '@/components/Pagination/Pagination';
import UserStationsTable from '@/components/UserStationsTable/UserStationsTable';
import { useAppDispatch } from '@/index';
import { RootState } from '@/store/rootReducer';
import {
    getUserStationsSlice,
    getUserStationsThunk
} from '@/store/userStations/getUserStations.slice';
import accessToken from '@/utils/helpers/accessToken.helper';
import request from '@/utils/helpers/apiResponse.helper';
import { generateFilter } from '@/utils/helpers/filter.helper';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { headersManageStationsByUser } from './UserStations.data';
import styles from './UserStations.module.scss';

const UserStations = () => {
    const navigate = useNavigate();
    const params = useParams();
    const appDispatch = useAppDispatch();

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [selectedItemModal, setSelectedItemModal] = useState('');

    const { userStations, max_pages, current_page } = useSelector(
        (state: RootState) => state.userStations.getUserStations
    );

    const handleChangePage = (page: number) => {
        appDispatch(
            getUserStationsThunk({
                id: params.id ? params.id : '',
                currentParams: generateFilter({ limit: 8, page })
            })
        );
    };

    useEffect(() => {
        handleChangePage(current_page);
    }, []);

    return (
        <>
            <div className={styles.container}>
                <div className={styles.content}>
                    <HeaderAdmin titleBold="Administración /&nbsp;" title="Instalaciones" />
                    <div className={styles.table}>
                        <UserStationsTable
                            headers={headersManageStationsByUser}
                            rows={userStations ? userStations : []}
                            onEditClick={() => {}}
                            isCheckable={false}
                            onCheck={() => {}}
                            onDeleteClick={(id) => {
                                setOpenModal(true);
                                setSelectedItemModal(id);
                            }}
                            options={{ delete: true, edit: false }}
                        />
                    </div>

                    <Pagination
                        handleChangePage={handleChangePage}
                        maxPages={max_pages}
                        currentPage={current_page}
                    />
                </div>
                <div className={styles.button}>
                    <Button
                        size={ButtonSizes.ADAPTABLE}
                        variant={ButtonVariants.PRIMARY}
                        onClick={() => {
                            navigate(`/manage-user-stations/${params.id}`);
                            appDispatch(getUserStationsSlice.actions.remove());
                        }}
                    >
                        + AGREGAR NUEVA INSTALACIÓN
                    </Button>
                </div>
            </div>
            <MyModal
                handleClose={() => setOpenModal(false)}
                isOpen={openModal}
                title=" ¿DESEA ELIMINAR LA ASIGNACIÓN?"
            >
                <div className={styles.contentModal}>
                    <div className={styles.buttons}>
                        <Button
                            size={ButtonSizes.ADAPTABLE}
                            variant={ButtonVariants.DANGER_OUTLINE}
                            onClick={() => {
                                setOpenModal(false);
                            }}
                        >
                            CANCELAR
                        </Button>
                        <Button
                            size={ButtonSizes.ADAPTABLE}
                            variant={ButtonVariants.DANGER}
                            onClick={() => {
                                setOpenModal(false);
                                request({
                                    url: `api/users/${params.id}/stations/${selectedItemModal}`,
                                    method: 'DELETE',
                                    extraHeaders: {
                                        Authorization: `Bearer ${accessToken.get()}`
                                    }
                                })
                                    .then((e) => {
                                        //TODO modal delete message
                                        toast.success(
                                            'La asignación se ha eliminado correctamente'
                                        );
                                        handleChangePage(current_page);
                                    })
                                    .catch(() => {
                                        toast.error('No se ha podido eliminar la asignación');
                                        handleChangePage(current_page);
                                    });
                            }}
                        >
                            ELIMINAR
                        </Button>
                    </div>
                </div>
            </MyModal>
        </>
    );
};

export default UserStations;
