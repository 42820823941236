import { QueryOption } from '@/components/SearchBar/SearchBar.types';
import { useAppDispatch } from '@/index';
import { RootState } from '@/store/rootReducer';

import { getStationsThunk, setSelectedStationId } from '@/store/stations/stations.slice';
import { generateFilter } from '@/utils/helpers/filter.helper';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function HomeAdminController() {
    const dispatch = useAppDispatch();
    const setSelectedStationAction = (selectedStationId?: string) =>
        dispatch(setSelectedStationId(selectedStationId));

    const selectedStationId = useSelector((state: RootState) => state.stations.selectedStationId);
    const { stations, max_pages, current_page } = useSelector(
        (state: RootState) => state.stations.stationList
    );
    const stationList = stations;

    const [searchText, setSearchText] = useState('');
    const [searchByValue, setSearchByValue] = useState<QueryOption>({
        value: 'client',
        label: 'Clientes'
    });

    const getStationsStatus = () => {
        //@ts-ignore
        dispatch(
            getStationsThunk(
                generateFilter({ [searchByValue.value]: searchText, limit: 8, page: current_page })
            )
        );
    };
    const handleChangePage = (page: number) => {
        dispatch(
            getStationsThunk(generateFilter({ [searchByValue.value]: searchText, limit: 8, page }))
        );
    };
    useEffect(() => {
        getStationsStatus();
    }, []);

    return {
        setSelectedStationAction,
        selectedStationId,
        stationList,
        getStationsStatus,
        searchText,
        searchByValue,
        setSearchText,
        setSearchByValue,
        handleChangePage,
        max_pages,
        current_page
    };
}

export default HomeAdminController;
