.container {
    width: 100%;
    height: var(--body-height);
    background-color: rgba(128, 128, 128, 0.144);
}
.tableCell {
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon {
    height: 40px;
    width: 40px;
    object-fit: contain;
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(128, 128, 128, 0.144);
    .headerContent {
        margin: 20px 30px 20px 30px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        .buttons {
            display: flex;
            flex-direction: row;
            width: 600px;
            gap: 10px;
        }
        .searchBar {
            width: 550px;
        }
    }
}

.table {
    overflow-x: scroll;
    margin: 0 30px 0 30px;
}

.options {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
}
.optionIcon {
    height: 25px;
    cursor: pointer;
}
.optionIcon:hover {
    opacity: 0.7;
}
