import Inicio from '@/assets/images/Inicio.jpg';
import Input from '@/components/Input/Input';
import { useState } from 'react';
import styles from './RecoverPassword.module.scss';
import EmailIcon from '@/assets/icons/EmailIcon.png';
import request from '@/utils/helpers/apiResponse.helper';
import { MyModal } from '@/components/MyModal/MyModal';
import Button from '@/components/Button/Button';
import { ButtonSizes, ButtonVariants } from '@/components/Button/Button.types';
import { useNavigate } from 'react-router-dom';
const RecoverPassword = () => {
    const [email, setEmail] = useState<string>('');
    const [openModal, setOpenModal] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleRecoverPassword = () => {
        request({
            url: '/api/users/recover-password',
            method: 'POST',
            data: { email: email }
        }).then((res) => {
            navigate('/login');
        });
    };
    return (
        <>
            <div
                className={styles.container}
                style={{
                    background: `url(${Inicio})`,
                    backgroundSize: 'cover'
                }}
            >
                <p className={styles.title}>Recuperar contraseña</p>
                <Input placeholder="Email" iconLeft={EmailIcon} value={email} onChange={setEmail} />
                <button
                    className={styles.button}
                    onClick={() => {
                        setOpenModal(true);
                    }}
                >
                    RECUPERAR CONTRASEÑA
                </button>
            </div>
            <MyModal
                handleClose={() => {
                    setOpenModal(false);
                }}
                isOpen={openModal}
                title="Recuperar Contraseña"
            >
                <div className={styles.contentModal}>
                    <p>Tu nueva contraseña será enviada al correo proporcionado.</p>
                    <p>ADVERTENCIA: La contraseña pasada ya no tendrá validez</p>
                    <div className={styles.buttons}>
                        <Button
                            size={ButtonSizes.ADAPTABLE}
                            variant={ButtonVariants.PRIMARY_OUTLINE}
                            onClick={() => {
                                setOpenModal(false);
                            }}
                        >
                            CANCELAR
                        </Button>
                        <Button
                            size={ButtonSizes.ADAPTABLE}
                            variant={ButtonVariants.PRIMARY}
                            onClick={() => {
                                setOpenModal(false);
                                handleRecoverPassword();
                            }}
                        >
                            RESTABLECER
                        </Button>
                    </div>
                </div>
            </MyModal>
        </>
    );
};

export default RecoverPassword;
