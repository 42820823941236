.header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    .title {
        width: auto;
        font-size: 35px;
        display: flex;
        flex-direction: row;
        &__bold {
            color: #777;
        }
        &__low {
            color: #999;
        }
    }
    .button {
        width: 120px;
        height: 50px;
    }
}
