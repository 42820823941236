.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-width: 200px;
    width: 100%;
    gap: 10px;

    .label {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        &__text {
            display: flex;
            justify-content: start;
            align-items: flex-start;
            font-weight: bolder;
        }
        &__editIcon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            color: var(--color-primary);
        }
        &__editIcon:hover {
            opacity: 0.6;
            cursor: pointer;
        }
    }

    .input {
        border: none;
        outline: none;
        width: 100%;
        height: 45px;
        border-radius: 5px;
        padding-left: 10px;
        border-bottom: 1px solid rgb(227, 227, 227);
    }
}
