import { combineReducers } from 'redux';
import { getUserDataSlice } from './getUserData';

import { authLoginSlice } from './login.slice';

export const authReducer = combineReducers({
    login: authLoginSlice.reducer,
    getUserData: getUserDataSlice.reducer
});

export const authActions = {
    ...authLoginSlice.actions,
    ...getUserDataSlice.actions
};
