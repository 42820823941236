.container {
    width: auto;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    .content {
        margin: 0;
        padding: 0;
        width: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        list-style: none;
        align-self: center;
        gap: 5px;
        .item {
            width: 40px;
            height: 40px;

            .button {
                border: 1px solid var(--color-primary);
                background-color: white;
                border-radius: 5px;
                width: 100%;
                height: 100%;
                background-color: transparent;
                font-size: 16px;
                color: var(--color-primary);
                &__active {
                    background-color: var(--color-primary);
                    color: white;
                }
            }
            .button:disabled {
                border: 1px solid #999999;
                background-color: #cccccc;
                color: #666666;
                pointer-events: none;
            }
            .button:hover {
                background-color: var(--color-primary);
                opacity: 0.8;
                color: white;
            }
        }
    }
}
