import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { StationList } from '@/models/StationList';
import styles from './HomeTable.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/rootReducer';

interface TableProps {
    data: StationList[] | any;
    columns: ColumnDef<StationList>[];
    selectedStationId?: string;
}

const Table = ({ data, columns, selectedStationId }: TableProps) => {
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel()
    });
    const role = useSelector((state: RootState) => state.auth.login.userData?.role);
    const isClient = role === 'client';
    return (
        <table className={styles.table}>
            <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header, index) => (
                            <th
                                key={header.id}
                                className={styles.tableHeader}
                                style={
                                    index === headerGroup.headers.length - 1
                                        ? { border: '0px' }
                                        : {}
                                }
                            >
                                {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                          header.column.columnDef.header,
                                          header.getContext()
                                      )}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody>
                {table.getRowModel().rows.map((row) => (
                    <tr
                        style={{
                            backgroundColor:
                                row.original.id === selectedStationId && isClient
                                    ? '#fee3b8'
                                    : 'white',
                            fontWeight:
                                row.original.id === selectedStationId && isClient
                                    ? 'bold'
                                    : 'normal'
                        }}
                        key={row.id}
                    >
                        {row.getVisibleCells().map((cell) => (
                            <td className={styles.tableCell} key={cell.id}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};
export default Table;
