import React from 'react';
import { Link } from 'react-router-dom';
import styles from './AdminLayout.module.scss';
import Logo_EMSOLAR from '../../assets/images/Logo_EMSOLAR.png';
import AdminLayoutController from './AdminLayout.controller';

interface AdminLayoutProps {
    children?: React.ReactNode;
}
const AdminLayout = ({ children }: AdminLayoutProps) => {
    const { onCloseSession } = AdminLayoutController();
    return (
        <>
            <div className={styles.header}>
                <Link to="/">
                    <img className={styles.logo} src={Logo_EMSOLAR} />
                </Link>

                <div className={styles.logoutContainer} onClick={() => onCloseSession()}>
                    Cerrar sesión
                </div>
            </div>
            <div className={styles.container}>{children}</div>
        </>
    );
};

export default AdminLayout;
