import accessToken from '@/utils/helpers/accessToken.helper';
import request, { ApiResponse } from '@/utils/helpers/apiResponse.helper';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

interface UserData {
    cif: string;
    email: string;
    maintenance_type: 'none' | 'standard' | 'premium';
    username: string;
    role: 'client' | 'technician' | 'admin';
}
export interface patchUserState {
    isLoading: boolean;
    user: UserData | null;
}

export const patchUserByIdThunk = createAsyncThunk<
    UserData,
    { id: string; cif: string; email: string; username: string },
    { rejectValue: Error }
>('users/patchUserById', async (params, thunkApi) => {
    return request({
        url: `/api/users/${params.id}`,
        method: 'PATCH',
        extraHeaders: {
            Authorization: `Bearer ${accessToken.get()}`
        },
        data: { cif: params.cif, email: params.email, username: params.username }
    })
        .then((res: ApiResponse<UserData>) => {
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            return thunkApi.rejectWithValue(err.data);
        });
});

export const patchUserSlice = createSlice({
    name: 'patchUserById',
    initialState: { isLoading: false, user: null } as patchUserState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(patchUserByIdThunk.fulfilled, (state, { payload }) => {
            (state.isLoading = false), (state.user = payload);
        });
    }
});
