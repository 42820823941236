export const headersManageStationsByUser = [
    'Nombre Instalación',
    'Modalidad',
    'Tarifa',
    //'Ps',
    'Administrar'
];

export const rowsManageStationsByUser: any[] = [
    {
        id: 'asd',
        client: '123123s',
        contractedRateByClient: { value: 'pool', label: 'pool' },
        contractedRate: { value: 'premium', label: 'premium' },
        PS: {
            P1: '23',
            P2: '23',
            P3: '23',
            P4: '23'
        }
    },
    {
        id: 'asd',
        client: '121621WE',
        contractedRateByClient: { value: 'fijo', label: 'fijo' },
        contractedRate: { value: 'premium', label: 'premium' },
        PS: {
            P1: '23',
            P2: '21',
            P3: '12',
            P4: '14',
            P5: '1',
            P6: '23'
        }
    },
    {
        id: 'asd',
        client: '125122341fr',
        contractedRateByClient: { value: 'pool', label: 'pool' },
        contractedRate: { value: 'premium', label: 'premium' },
        PS: {
            P1: '23',
            P2: '23',
            P3: '23',
            P4: '23'
        }
    },
    {
        id: 'asd',
        client: '34573425OUI',
        contractedRateByClient: { value: 'fijo', label: 'fijo' },
        contractedRate: { value: 'premium', label: 'premium' },
        PS: {
            P1: '23',
            P2: '21',
            P3: '12',
            P4: '14',
            P5: '1',
            P6: '23'
        }
    },
    {
        id: 'asd',
        client: '64364PO',
        contractedRateByClient: { value: 'pool', label: 'pool' },
        contractedRate: { value: 'premium', label: 'premium' },
        PS: {
            P1: '23',
            P2: '23',
            P3: '23',
            P4: '23'
        }
    }
];
export const initialValuesManageStationsByUser = {
    contractedRateByClient: { value: 'fijo', label: 'Importe fijo ' },
    contractedRate: { value: '2.0TD', label: '2.0TD' },
    P1: '23',
    P2: '21',
    P3: '12',
    P4: '14',
    P5: '1',
    P6: '23'
};
export interface StationByUser {
    id: string;
    client: string;
    contractedRateByClient: { value: string; label: string };
    contractedRate: { value: string; label: string };
    PS: {
        P1?: string | null;
        P2?: string | null;
        P3?: string | null;
        P4?: string | null;
        P5?: string | null;
        P6?: string | null;
    };
}
