.container {
    background-color: rgb(245, 243, 243);
    min-height: var(--body-height);

    .content {
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 40px;

        .formContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 50px;
            .formContent {
                width: 800px;
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 20px;
            }

            .deleteOption {
                display: flex;
                flex-direction: column;
                justify-content: start;
                gap: 10px;
                &__label {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    font-weight: bolder;
                }
                &__button {
                    width: 100px;
                    height: 38px;
                }
            }
            .stations {
                width: 50%;
            }

            .buttons {
                width: 500px;
                height: 50px;
                display: flex;
                flex-direction: row;
                gap: 30px;
            }
        }
    }
}

.contentModal {
    margin: 40px 20px 25px 20px;
    .buttons {
        display: flex;
        flex-direction: row;
        gap: 20px;
    }
}
