.timeSelectorContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-height: 40px;
    min-width: 100%;
    margin-left: 400px;
}

.label {
    font-size: 22px;
    color: #777;
    padding: 0px 15px;
    white-space: nowrap;
}

.is24HoursContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    min-width: 100px;
    white-space: nowrap;
}

.is24HoursContainer:hover {
    cursor: pointer;
}

.activeDot {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: #f1a263;
    border: 1px solid #7c6c50;
}

.inactiveDot {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: #f4f4f4;
    border: 1px solid #bfbfbf;
}

.input {
    padding: 5px 15px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #fcfcfc;
}