import { StylesConfig } from 'react-select';

const customStylesInputSelect: StylesConfig = {
    control: (base, state) => ({
        ...base,
        width: '100%',
        border: state.isFocused ? '1px solid white' : `1px solid white;`,
        background: 'white',
        boxShadow: 'none',
        '&:hover': {
            borderColor: 'white'
        }
    }),
    option: (provided, state) => ({
        ...provided,
        background: state.isFocused ? 'var(--color-primary)' : 'white',
        color: state.isFocused ? 'white' : '#333',
        '&:active': {
            background: 'var(--color-primary)'
        }
    }),
    container: (provided) => ({
        ...provided,
        width: '100%'
    }),
    menu: (provided) => ({
        ...provided,
        width: 'max-content',
        minWidth: '100%',
        zIndex: 'var(--z-level-3)'
    })
};
export default customStylesInputSelect;
