import { useNavigate } from 'react-router-dom';
import Button from '../Button/Button';
import { ButtonSizes, ButtonVariants } from '../Button/Button.types';
import styles from './HeaderAdmin.module.scss';
interface HeaderAdminProps {
    title: string;
    titleBold: string;
}
const HeaderAdmin = ({ title, titleBold }: HeaderAdminProps) => {
    const navigate = useNavigate();
    return (
        <div className={styles.header}>
            <div className={styles.title}>
                <div className={styles.title__bold}>{titleBold}</div>
                <div className={styles.title__low}>{title}</div>
            </div>
            <div className={styles.button}>
                <Button
                    onClick={() => {
                        navigate(-1);
                    }}
                    size={ButtonSizes.ADAPTABLE}
                    variant={ButtonVariants.PRIMARY}
                >
                    VOLVER
                </Button>
            </div>
        </div>
    );
};

export default HeaderAdmin;
