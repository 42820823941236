import styles from './Input.module.scss';

interface InputProps {
    placeholder: string;
    onChange: (text: string) => void;
    iconLeft: string;
    value: string;
    secureTextEntry?: boolean;
    [x: string]: any;
}
const Input = ({
    placeholder,
    onChange,
    iconLeft,
    value,
    secureTextEntry = false,
    ...props
}: InputProps) => {
    return (
        <div className={styles.container}>
            <img className={styles.icon} src={iconLeft} />
            <input
                className={styles.input}
                onChange={(text) => onChange(text.currentTarget.value)}
                placeholder={placeholder}
                value={value}
                type={secureTextEntry ? 'password' : 'text'}
                {...props}
            />
        </div>
    );
};

export default Input;
